<template>
  <div class="container-add">
    <yiyu-navbar :tab-title="tabTitle"> </yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      :form="form"
      @submit="onSubmit"
      ref="form"
    >
    </yiyu-form>
    <yiyu-picker
      v-if="showUnitPicker"
      :curKey="form.c_unit_key"
      :show.sync="showUnitPicker"
      :columns="unitColumns"
      value-key="name"
      keyName="c_unit_key"
      @confirm="confirmPicker($event, 'c_unit')"
      @cancel="cancelPicker('c_unit')"
    >
    </yiyu-picker>
    <yiyu-picker
      v-if="showStagePicker"
      :curKey="form.c_stage"
      :show.sync="showStagePicker"
      :columns="stageColums"
      value-key="name"
      keyName="c_stage"
      @confirm="confirmPicker($event, 'c_stage')"
      @cancel="cancelPicker('c_stage')"
    >
    </yiyu-picker>

    <fixed-btn
      btnTxt="保存"
      :leftTxt="leftTxt"
      @leftClick="leftClick"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
    <y-dialog-page-list
      :show.sync="nextShow"
      :title="tabTitle + '成功'"
      subtitle="请选择下一步："
      :list="nextOpts"
      @confirm="nextHandle"
    />
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
import { YDialogPageList } from 'yiyu-vant-ui/lib/dialog'
import { produce_mutations } from '@/store/modules/produce'

export default {
  name: 'edit',
  components: {
    yiyuForm,
    yiyuPicker,
    fixedBtn,
    yiyuNavbar,
    YDialogPageList,
  },
  computed: {
    formOpts() {
      let _this = this
      let opts = [
        {
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.c_name,
              name: 'c_name',
              label: '容器名称',
              placeholder: '请输入容器名称',
              rules: [{ required: true, message: '请输入容器名称' }],
            },
            {
              required: !this.disabled,
              colon: true,
              formType: 'select', // 默认
              value: this.form.c_stage,
              name: 'c_stage',
              label: '使用阶段',
              placeholder: '请选择容器使用阶段',
              disabled: this.disabled,
              click() {
                if (!_this.disabled) {
                  _this.showStagePicker = true
                }
              },
              rules: [
                { required: !this.disabled, message: '请选择容器使用阶段' },
              ],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.c_unit,
              name: 'c_unit',
              label: '单位',
              placeholder: '请选择容器计量单位',
              click() {
                _this.showUnitPicker = true
              },
              rules: [{ required: true, message: '请选择容器计量单位' }],
            },

            {
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.capacity,
              name: 'capacity',
              label: '容量',
              extra: this.form.c_unit,
              placeholder: '请输入容器最大容量',
              rules: [{ required: true, message: '请输入容器最大容量' }],
            },
            {
              show: this.form.c_name != '培养皿',
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.usage_day,
              name: 'usage_day',
              extra: '天',
              label: '使用天数',
              rules: [{ required: true, message: '请输入使用天数' }],
            },
          ],
        },
      ]
      return opts
    },
    tabTitle() {
      let str
      if (this.status == 'add') {
        str = '新增'
      } else {
        str = '编辑'
      }
      return str + '容器'
    },
  },
  data() {
    return {
      initForm: {}, // 用来重置表单
      btnLoading: false,
      isLoading: false,
      unitLoading: false,
      thisAlias: this,
      disabled: false,
      nextShow: false,
      leftTxt: '重置',
      status: 'add',
      form: {
        c_name: '', //容器名称
        c_stage: '', //使用阶段//1保种阶段，2
        c_stage_key: '',
        c_unit_key: '',
        c_unit: '', //单位//1代表ml，2代表L,3代表m^3;
        capacity: '', //容量；
        usage_day: '', //使用天数；
        c_key: undefined, //修改时需要传入;
      },
      showUnitPicker: false,
      showStagePicker: false,
      unitColumns: ['ml', 'L', 'm³', '吨'],
      stageColums: ['保种阶段', '一级', '二级', '三级'],
      nextOpts: [
        {
          name: '继续新增容器',
          key: 'continueAdd',
        },
        {
          name: '藻类批次列表',
          key: 'FF_produce',
        },
        {
          name: '容器列表',
          key: 'containerList',
        },
        {
          name: '发送通知',
          key: 'notice',
        },
        {
          name: '返回入口',
          key: 'back',
        },
      ],
    }
  },
  mounted() {
    this.thisAlias = this
    console.log(this.$route.query)
    if (this.$route.query) {
      let query = this.$route.query
      this.form.c_key = query.c_key
      if (this.form.c_key) {
        this.status = 'edit'
        this.disabled = true
        this.leftTxt = '删除'
        this.getData()
      }
    }
  },
  activated() {},
  methods: {
    confirmPicker(e, type) {
      switch (type) {
        case 'c_unit':
          this.form.c_unit_key = e[1] + 1
          this.form.c_unit = e[0]
          this.showUnitPicker = false
          break
        case 'c_stage':
          this.form.c_stage_key = e[1] + 1
          this.form.c_stage = e[0]
          this.showStagePicker = false
          break
      }
    },
    // 继续新增
    continueAdd() {
      this.nextShow = false
      this.form = this.$options.data.call(this).form
    },
    getData() {
      this.isLoading = true
      this.$get('/container/getContainerOne', { c_key: this.form.c_key }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false
            let data = res.data
            for (let key in data) {
              this.form[key] = data[key] || this.form[key]
            }
          }
        },
      )
    },
    nextHandle(key) {
      switch (key) {
        case 'continueAdd':
          this.continueAdd()
          break
        case 'back':
          this.$go(-1, this)
          break
        default:
          this.toPage(key)
      }
    },
    toPage(type) {
      switch (type) {
        // 新增模板
        case 'containerEdit':
          this.$toPage(
            this,
            'containerEdit',
            {},
            {
              route_type: '$replace',
            },
          )
          break
        case 'FF_produce':
          this.$store.commit(produce_mutations.ACTIVE_TAB, 'algae')
          this.$toPage(this, 'FF_produce', {})
          break
        case 'publish-add':
          this.$toPage(this, 'publish', { status: 'add' })
          break
        case 'containerList':
          this.$toPage(this, 'containerList', {})
          break
      }
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    cancelPicker(type) {
      switch (type) {
        case 'c_unit':
          this.showUnitPicker = false
          break
        case 'c_stage':
          this.showStagePicker = false
          break
      }
    },
    leftClick() {
      if (this.leftTxt == '重置') {
        let data = JSON.parse(JSON.stringify(this.initForm))
        for (let key in this.form) {
          data[key] = data[key] || undefined
        }
        this.form = data
      } else {
        this.$dialog
          .confirm({
            title: '删除提醒',
            message: '是否确认删除该容器',
          })
          .then(() => {
            this.$get('/container/delContainer', {
              c_key: this.form.c_key,
            }).then((res) => {
              if (res.code == 10000) {
                this.$toast.success('删除成功！')
                this.$go(-1, this)
              }
            })
          })
          .catch(() => {})
      }
    },
    onSubmit() {
      switch (this.form.c_stage) {
        case '保种':
          this.form.c_stage_key = 1
          break
        case '一级':
          this.form.c_stage_key = 2
          break
        case '二级':
          this.form.c_stage_key = 3
          break
        case '三级':
          this.form.c_stage_key = 4
          break
      }
      switch (this.form.c_unit) {
        case 'ml':
          this.form.c_unit_key = 1
          break
        case 'L':
          this.form.c_unit_key = 2
          break
        case 'm³':
          this.form.c_unit_key = 3
          break
        case '吨':
          this.form.c_unit_key = 4
          break
      }
      let params = {
        c_name: this.form.c_name, //容器名称
        c_stage: this.form.c_stage_key, //使用阶段//1保种阶段，2
        c_unit: this.form.c_unit_key, //单位//1代表ml，2代表L,3代表m^3;
        capacity: this.form.capacity, //容量；
        usage_day: this.form.usage_day, //使用天数；
        c_key: this.form.c_key, //修改时需要传入;
      }
      if (this.form.c_name == '培养皿') {
        params['usage_day'] = 999999
      }

      this.btnLoading = true
      this.$post('/container/addContainer', params).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.nextShow = true

          console.log(res)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
