<template>
  <div>
    <yiyu-navbar tab-title="藻类详情" />
    <div v-loading="isLoading">
      <div class="alga-list-detail-title">
        <span class="font-extrabold">{{ batch }}</span>
        <handle-popver
          @edit="handleEditAlga"
          @del="handleAlgaDelete"
        ></handle-popver>
      </div>
      <div v-show="batchSource.text" class="mx-4">
        <span>来源:</span>
        <span
          class="main-c cursor-pointer"
          @click="$router.push(`/alga/detail/${batchSource.key}`)"
          >{{ batchSource.text }}</span
        >
      </div>

      <yiyu-tabs v-model="tabCur" style="width: 100%">
        <template v-for="(item, index) in tabData">
          <yiyu-tab-pane :key="index" :name="item.name" :label="item.label" />
        </template>
      </yiyu-tabs>

      <component
        :is="tabCur"
        :data="data[tabCur]"
        :algae-key="algae_key"
      ></component>

      <van-icon
        id="addBtn"
        class="popup-icon"
        v-draggable:[draggableOptions]="'85,90'"
        name="add"
        ref="addBtn"
      />

      <div class="y-popper" ref="popper">
        <div
          v-for="item in popoverActions"
          class="y-popper-action"
          :key="item.text"
          @click="handlePopoverClick(item.click)"
        >
          <div class="y-popper-action-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handlePopver from '@/components/feedback/handlePopver'
import yiyuNavbar from '@/components/navbar'
import yiyuTabs from '@/components/tab/tabs.vue'
import yiyuTabPane from '@/components/tab/tabPane.vue'
import { loading, draggable } from 'yiyu-vant-ui/lib/_directive'

import AlgaListDetailCur from './AlgaListDetailCur.vue'
import AlgaListDetailHistory from './AlgaListDetailHistory.vue'
import tippy from 'tippy.js'
import { Dialog } from 'vant'

export default {
  name: 'algaListDetail',
  components: {
    handlePopver,
    yiyuTabs,
    yiyuTabPane,
    yiyuNavbar,
    AlgaListDetailCur,
    AlgaListDetailHistory,
  },
  directives: {
    loading,
    draggable,
  },
  data() {
    return {
      batch: '',
      batchSource: {
        text: '',
        key: '',
      },
      isLoading: false,
      tabData: [
        {
          label: '当前数据',
          name: 'AlgaListDetailCur',
        },
        { label: '历史数据', name: 'AlgaListDetailHistory' },
      ],
      tabCur: 'AlgaListDetailCur',
      data: {},
      popoverShow: false,
      popoverActions: [
        {
          text: '复制',
          click: () => {
            this.$toPage(this, 'algaCopy', {
              algae_key: this.algae_key,
              cached: true,
            })
          },
        },
        {
          text: '数据调整',
          click: () => {
            this.$toPage(this, 'algaAdjust', {
              algae_key: this.algae_key,
              cached: true,
            })
          },
        },
        {
          text: ' 淘汰记录',
          click: () => {
            this.$toPage(this, 'algaOut', {
              algae_key: this.algae_key,
              cached: true,
            })
          },
        },
      ],
      popperInstance: null,
      draggableOptions: {
        onMove: () => {
          if (this.popoverShow) this.popperInstance[0].popperInstance.update()
        },
      },
      page: 1,
      algae_key: '',
    }
  },
  mounted() {
    const { key: algae_key } = this.$route.params
    this.algae_key = algae_key
    this.getData()
    this.popperInstance = tippy('#addBtn', {
      content: this.$refs.popper,
      trigger: 'click',
      placement: 'left',
      interactive: true,
      onShow: () => {
        this.popoverShow = true
      },
      onHide: () => {
        this.popoverShow = false
      },
    })
  },
  methods: {
    handlePopoverClick(fn) {
      this.popoverShow = false
      fn()
    },
    getData() {
      this.$get('/algae/curDetail', {
        algae_key: this.algae_key,
        page: this.page,
      }).then((res) => {
        const { batch, curData, origin } = res.data
        this.batchSource.text = origin?.batch
        this.batchSource.key = origin?.origin_key
        this.batch = batch
        this.$set(this.data, 'AlgaListDetailCur', curData)
        this.isLoading = false
      })
    },
    handleEditAlga() {
      this.$router.push({
        name: 'algaEdit',
        params: { readonly: true, algae_key: this.algae_key, cached: true },
      })
    },
    handleAlgaDelete() {
      Dialog.confirm({
        title: '是否确认藻类',
      }).then(() => {
        this.$get('/algae/delAlgae', {
          algae_key: this.algae_key,
        })
          .then((res) => {
            if (res.code === 10000) {
              this.$toast('删除成功')
              this.$go(-1, this)
            } else {
              this.$toast('请稍后再试' + res.code)
            }
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
  },
  beforeRouteUpdate(to, from, next) {
    const { key } = to.params
    this.algae_key = key
    this.getData()
    next()
  },
}
</script>

<style lang="less" scoped>
.alga-list-detail-title {
  display: flex;
  justify-content: space-between;
  margin: 0px 1rem;
}
.popup-icon {
  font-size: 2.5rem;
  color: @mainColor;
}
.y-popper {
  // background-color: #4a4a4a;
  border-radius: 4px;
  // if not last child add border
  &-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 44px;
    padding: 0px 16px;

    &-text {
      color: white;
    }
  }
}
</style>
