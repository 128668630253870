<template>
  <div>
    <navbar tab-title="分配藻类" />
    <div class="m-4 border shadow rounded-md p-2">
      <div class="list-item-box">
        <div class="list-item-box-item" v-for="item in headerList" :key="item">
          <div class="label text-justify-hack">
            {{ wordMap[item] }}
          </div>
          :
          <div class="truncate">
            {{
              getBasementData(item)
                | appendUnit(item, Object.values(fieldMap), 'L')
                | checkEmpty
            }}
          </div>
        </div>
      </div>
      <YLayoutBottomInfo
        :name="basementData.operate_man"
        :time="basementData.created_at"
      />
    </div>
    <div class="tw-divider" />
    <yiyu-form :_this="this" :formOpts="formOpts">
      <template #batch-list>
        <div class="flex justify-between items-center p-2">
          <div class="yiyu-required">批次</div>
          <van-button
            size="small"
            type="primary"
            native-type="button"
            @click="handleSelectBatch"
            >选择批次</van-button
          >
        </div>
        <transition-group name="fade">
          <div
            class="p-2 border rounded-md mb-2"
            v-for="batch in batchList"
            :key="batch.algae_key"
          >
            <div class="flex items-center justify-between">
              <span>{{ batch.name }}</span>
              <van-icon
                name="delete-o"
                @click="
                  handleRemove('batch', {
                    batch: batch.name,
                  })
                "
              />
            </div>
            <van-field
              v-model="batch.totalDistribute"
              required
              label="总分配量"
            />
            <van-field
              :value="batch.pool.length"
              required
              label="池数"
              disabled
            />
            <div class="-mx-2">
              <div
                v-for="pool in batch.pool"
                class="flex items-center m-2"
                :key="pool.ap_key"
              >
                <span class="ml-2">{{ pool.name }}</span>
                <div class="flex flex-row px-4 py-2">
                  <van-radio-group
                    v-model="pool.end_status"
                    direction="horizontal"
                  >
                    <van-radio name="1">完结</van-radio>
                    <van-radio name="2">未完结</van-radio>
                  </van-radio-group>
                </div>
                <van-icon
                  class="ml-auto"
                  name="delete-o"
                  @click="
                    handleRemove('pool', {
                      batch: batch.name,
                      key: pool.ap_key,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </transition-group>
      </template>
      <template #footer>
        <fixed-btn btnTxt="提交" btnNativeType="submit" @click="handleSubmit" />
      </template>
    </yiyu-form>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import YiyuForm from '@/components/form/yiyuForm.vue'
import FixedBtn from '@/components/button/fixedBtn.vue'
import bus from '@/utils/bus'
import algaMixin from './algaMixin'
import { YLayoutBottomInfo, YDialog } from 'yiyu-vant-ui'
import { distributeCardAdapter } from './algaAdpater'
import checkEmpty from '@/filters/checkEmpty'
// 存在这里的字段会被 自动 添加‘L’后缀.
const fieldMap = {
  distributed: 'total_distribute',
  distributionRequirement: 'quantity',
  distributionExpecting: 'expect_distribution',
}

export default {
  name: 'algaDistribute',
  components: { navbar, YiyuForm, FixedBtn, YLayoutBottomInfo },
  mixins: [algaMixin],
  filters: {
    appendUnit(value, field, target, unit) {
      if (Array.isArray(target))
        return target.indexOf(field) === -1 ? value : value + unit
      if (field == target) return value + unit
      return value
    },
    checkEmpty,
  },
  data() {
    return {
      basementData: {},
      headerList: [
        'mc_name',
        fieldMap.distributionRequirement,
        'reque_day',
        fieldMap.distributed,
        fieldMap.distributionExpecting,
        'tag',
      ],
      batchList: [],
      params: {
        operation_date: this.$dayjs(new Date()).format('YY-MM-DD HH:mm'),
        sign_key: '',
      },
      fieldMap,
    }
  },
  computed: {
    wordMap() {
      return Object.assign(this.algaListItemWordMap.distribute, {
        [fieldMap.distributionExpecting]: '待分配',
      })
    },
    formOpts() {
      return [
        {
          child: [
            {
              label: '操作时间',
              required: true,
              disabled: true,
              value: this.params.operation_date,
            },
          ],
          slot: 'batch-list',
        },
      ]
    },
  },
  created() {
    bus.$on('update-batch-list', (e) => {
      this.batchList = e
    })

    const { basement } = this.$route.params
    const _e =
      basement[fieldMap.distributionRequirement] -
      basement[fieldMap.distributed]
    basement[fieldMap.distributionExpecting] = _e < 0 ? 0 : _e
    this.basementData = basement

    this.params.sign_key = this.$route.params?.sign_key ?? ''
    if (this.$route.params.idx == undefined) return
    const idx = this.$route.params.idx
    this.$get('/distribute/getDistributionOne', {
      rr_key: basement.rr_key,
    })
      .then(({ data }) => {
        // 无数据直接返回
        if (data.length <= 0) return
        // ⚠️ 拿最新的操作中的数据当作是数据库最新数据.
        // 这里默认数组第一个是最新数据
        const { dist_list } = data[idx]
        let batchList = []
        dist_list.forEach((batch) => {
          batchList.push(
            distributeCardAdapter({
              name: batch.list[0]?.batch || '无',
              key: batch.ws_key,
              pools: batch.list.map((pool) => ({
                name: pool.ws_name,
                ap_key: pool?.ap_key,
                p_key: pool.p_key,
                end_status: pool.state,
                value: pool.assigned_amount,
                dr_key: pool?.dr_key,
              })),
            }),
          )
        })

        this.batchList = batchList
      })
      .catch((err) => {
        console.error(err)
      })
  },
  methods: {
    getBasementData(item) {
      if (item == 'tag') return this.basementData[item] == 1 ? '上午' : '下午'
      return this.basementData[item]
    },
    handleSelectBatch() {
      this.$router.push({
        name: 'algaDistributeBatchSelector',
        params: { cached: true },
      })
    },
    handleSubmit() {
      bus.$off('update-batch-list')
      let _total = 0
      for (let i = 0; i < this.batchList.length; i++) {
        _total += Number(this.batchList[i].totalDistribute)
      }
      if (_total > this.basementData.quantity) {
        this.$toast('总分配量不能大于需求量')
        return
      }
      if (_total == 0 || this.batchList.length === 0) {
        this.$toast('您没有进行分配')
        return
      }

      const pool_list = []

      for (const batch of this.batchList) {
        if (!batch?.pool) continue
        batch.pool.forEach((pool) => {
          const poolParam = {
            algae_key: batch.algae_key,
            ap_key: pool.ap_key,
            assigned_amount: Number(batch.totalDistribute) / batch.pool.length,
            end_status: pool.end_status,
          }
          if (pool?.dr_key != undefined) poolParam.dr_key = pool.dr_key
          pool_list.push(poolParam)
        })
      }

      this.$post('/distribute/addDistribution', {
        rr_key: this.basementData.rr_key,
        operation_date: this.params.operation_date,
        sign_key: this.params.sign_key,
        pool_list,
      })
        .then(() => {
          YDialog({
            type: 'list',
            title: '分配成功',
            subtitle: '请选择下一步:',
            data: [
              {
                name: '返回上一页',
                key: 'back',
                onConfirm: () => {
                  this.$router.back()
                },
              },
              {
                name: '发送通知',
                key: 'notice',
                onConfirm: () => {
                  this.$router.replace('notice')
                },
              },
            ],
          })
        })
        .catch((err) => {
          this.$toast('请稍后再试')
          console.error(err)
        })
    },
    handleRemove(type, options) {
      const batchIdx = this.batchList
        .map((batch) => batch.name)
        .indexOf(options?.batch)

      if (batchIdx === -1) return

      if (type === 'batch') {
        this.batchList.splice(batchIdx, 1)
        return
      }

      if (type === 'pool') {
        const poolIdx = this.batchList[batchIdx].pool
          .map((pool) => pool.ap_key)
          .indexOf(options.key)

        if (poolIdx !== -1) this.batchList[batchIdx].pool.splice(poolIdx, 1)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './alga.less';
</style>
