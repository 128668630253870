<template>
  <div class="yiyu-list pb-20">
    <YFilter
      :data="HisfilterOptions"
      ref="filter"
      @search="mixinFilterSearch"
      @reset="filterReset"
    >
      <YFilterItemCalendar slot="操作时间" type="range" />
    </YFilter>
    <div
      class="m-4"
      v-for="(manipulation, idx) in manipulateHistory"
      :key="idx"
    >
      <div class="font-semibold mb-2">
        {{ timeFormater(manipulation.record_date) }}
      </div>
      <grid-box :value="gridBoxData(manipulation)" />
    </div>
  </div>
</template>

<script>
import { YFilter, YFilterItemCalendar } from 'yiyu-vant-ui'
import algaMixin from './algaMixin'
import { filters } from './algaFilterPreset'
import GridBox from '@/components/layout/gridBox.vue'

export default {
  props: {
    algaeKey: {
      type: String,
      default: '',
    },
  },
  components: {
    YFilter,
    YFilterItemCalendar,
    GridBox,
  },
  mixins: [algaMixin],
  data() {
    return {
      count: 10,
      HisfilterOptions: [
        {
          label: '数据分类',
          children: [
            {
              label: '请选择历史类型',
              keyName: 'type',
              children: [
                {
                  label: '调整记录',
                  key: '1',
                },
                {
                  label: '新增记录',
                  key: '3',
                },
                {
                  label: '淘汰记录',
                  key: '2',
                },
              ],
            },
          ],
        },
        {
          label: '阶段',
          children: [
            {
              label: '请选择阶段',
              keyName: 'stage',
              children: [
                {
                  label: '三级',
                  key: 4,
                },
                {
                  label: '二级',
                  key: 3,
                },
                {
                  label: '一级',
                  key: 2,
                },
                {
                  label: '保种',
                  key: 1,
                },
              ],
            },
          ],
        },
        filters.apply(this).container,
        { label: '操作时间', keyName: ['start_date', 'end_date'] },
      ],
      manipulateHistory: [],
      containerList: [],
    }
  },
  computed: {
    algaListDetailHistoryCard() {
      return (stage) => {
        let adjData = [
          'ori_stage',
          'ori_container',
          'ori_date',
          'ori_cnumber',
          'loss_num',
          'adj_stage',
          'adj_container',
          'adj_number',
        ]
        if (stage == 4) adjData.splice(6, 1)
        return {
          adjData,
          addData: ['stage', 'container', 'breed_day', 'container_num'],
          lossData: ['stage', 'breed_day', 'loss'],
        }
      }
    },
    timeFormater() {
      return (time) => this.$dayjs(time).format('YY-MM-DD')
    },
    filterValue() {
      return (field, value) => {
        switch (field) {
          case 'breed_day':
            return `${value}天`
          case 'adj_number':
          case 'loss_num':
          case 'loss':
          case 'container_num':
          case 'ori_cnumber': {
            if (!value) return '0个'
            return `${value}个`
          }
          case 'ori_date':
            return `第${value}天`
          case 'ori_stage':
          case 'adj_stage':
          case 'stage':
            return this.mixinStageMap[value]
          default:
            return value
        }
      }
    },
    gridBoxData({ algaListDetailHistoryCard, algaListItemWordMap }) {
      return (manipulation) =>
        algaListDetailHistoryCard(manipulation.adj_stage)[
          manipulation.type
        ].map((field) => ({
          label: algaListItemWordMap[field],
          value: this.filterValue(field, manipulation[field]),
        }))
    },
  },
  mounted() {
    // this.filterUpdateOptions()
    this.getData()
  },
  methods: {
    initData() {
      this.manipulateHistory = []
    },
    getData(page = 1, count, params) {
      if (!this.algaeKey) return
      this.$get('/algae/lastDetail', {
        algae_key: this.algaeKey,
        size: count,
        page,
        // FIXME: 后端处理? 前端处理?
        // 如果需要前端处理, 主要是:
        // 筛选 阶段 非保种(1) 时移除 添加历史(type=3)
        // 筛选 阶段 保种时(1) 带上 添加历史(type=3)
        type: [1, 2, 3],
        ...params,
      }).then((res) => {
        const { lastData, containerList } = res.data
        this.manipulateHistory = lastData
        this.containerList = containerList
      })
    },
  },
}
</script>

<style lang="less" scope>
@import './alga.less';
</style>
