<template>
  <div class="main">
    <yiyu-bar :tab-title="tabTitle"></yiyu-bar>
    <div class="main-content">
      <div class="row-between">
        <div class="yiyu-detail-title">{{ p_name || '暂无' }}</div>
        <handle-popver
          @del="del"
          :actions="actions"
          @edit="edit"
        ></handle-popver>
      </div>
      <div class="yiyu-detail-title" @click="selectPanl">
        {{ batch || '暂无' }}
        <van-icon v-show="!show" name="arrow" />
        <van-icon v-show="show" name="arrow-down" />
      </div>
      <classify-tag-popup
        :show.sync="show"
        v-if="show"
        :title="title"
        :list="seedShrimpList"
        pLabel="label"
        pChild="value"
        cValue="batch"
        cKey="algae_key"
        @confirm="confirmPicker($event)"
        @cancel="cancel"
        :cancelShow="cancelShow"
        :single="single"
        :pCurKey.sync="algae_key"
      >
        <template #top>
          <van-search
            @search="getBatch"
            v-model="keyword"
            placeholder="可搜索种虾批次"
          ></van-search>
        </template>
      </classify-tag-popup>
      <yiyu-detail :formOpts="formOpts">
        <template #detail>
          <data-field :dataObj="dataObj"></data-field>
        </template>
      </yiyu-detail>
      <van-button block @click="toPage('algaOut')">淘汰</van-button>
      <van-dialog
        v-model="editShow"
        title="修改池名称"
        show-cancel-button
        @confirm="addPool"
        @cancel="editShow = false"
        :before-close="beforeClose"
      >
        <van-field
          v-model="editData.p_name"
          placeholder="请输入育苗池名称"
        ></van-field>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import classifyTagPopup from '@/components/popup/classifyTag'
import handlePopver from '@/components/feedback/handlePopver'
import yiyuBar from '@/components/navbar'
import yiyuDetail from '@/components/detail'
import dataField from '@/components/layout/dataField'

export default {
  name: 'aPoolDetail',
  components: {
    yiyuBar,
    yiyuDetail,
    handlePopver,
    dataField,
    classifyTagPopup,
    // empty,
  },
  computed: {
    formOpts() {
      return [
        {
          title: '',
          slot: 'detail',
        },
      ]
    },
    dataObj() {
      return [
        [
          {
            data:
              this.list.breeding_date &&
              this.$dayjs(this.list.breeding_date).format('YY/MM/DD'),
            descText: '入池日期',
          },
          {
            data: this.list?.breed_day,
            descText: '培育天数',
          },
          {
            data: this.list?.assigned_amount,
            unitText: 'L',
            descText: '分配量',
          },
        ],
        [
          {
            data:
              this.list.operation_date &&
              this.$dayjs(this.list.operation_date).format('YY/MM/DD'),
            descText: '分配日期',
          },
          {
            data:
              this.list.finish &&
              this.$dayjs(this.list.finish).format('YY/MM/DD'),
            descText: '淘汰日期',
          },
        ],
      ]
    },
    tabTitle() {
      return `${this.list.mc_name || ''}基地池详情`
    },
    p_name() {
      return this.list.p_name
    },
    batch() {
      return this.list.batch
    },
    seedShrimpList() {
      return [
        this.batchList,
        // name: this.batchList.label,
        // children: this.batchList,
      ]
    },
  },
  data() {
    return {
      editShow: false,
      postLoading: false,
      curkey: 'ss_key',
      show: false,
      single: true,
      keyword: '',
      cancelShow: true,
      title: '请选择种虾批次',
      actions: [
        {
          text: '编辑',
          fn: 'edit',
        },
        {
          text: '删除',
          fn: 'del',
        },
      ],
      list: {}, // 池详情列表
      ap_key: '',
      algae_key: '',
      p_key: '',
      batchList: {},
      editData: {},
    }
  },
  created() {
    if (this.$route.query) {
      this.p_key = this.$route.query.p_key
      this.algae_key = this.$route.query.algae_key
      this.ap_key = this.$route.query.ap_key
      this.getData()
    }
    this.getBatch()
  },
  methods: {
    toPage(url) {
      this.$toPage(this, url, { algae_key: this.algae_key })
    },
    edit() {
      this.editShow = true
      this.editData = JSON.parse(JSON.stringify(this.list))
    },
    // 添加修改池
    addPool() {
      if (!this.editData.p_name) {
        this.$toast.fail('育苗池名称不能为空哦')
        return
      }
      this.$post('/pool/addPool', {
        ws_key: this.list.ws_key,
        p_key: this.p_key,
        name: this.editData.p_name,
      }).then((res) => {
        if (res.code == 10000) {
          this.$toast.success('修改池名称成功!')
          this.list.p_name = this.editData.p_name
          this.editShow = false
        }
      })
    },
    beforeClose(action, done) {
      switch (action) {
        case 'cancel':
          done()
          break
        case 'confirm':
          done(false)
          break
      }
    },
    confirmPicker(e) {
      this.algae_key = e.key
      this.show = false
      this.getData()
    },
    del() {
      this.$dialog
        .confirm({
          title: '删除池',
          message: '是否确认要删除当前池',
        })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/pool/delPool', { p_key: this.p_key }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除种虾池成功')
              this.$go(-1, this)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },

    getData() {
      this.$get('/ap/getApDetail', {
        p_key: this.p_key,
        ap_key: this.ap_key,
        algae_key: this.algae_key,
      }).then((res) => {
        this.list = res.data
      })
    },
    selectPanl() {
      this.show = !this.show
    },
    getBatch() {
      let parameters = {
        select_items: ['Algae'],
        params: {
          keyword: this.keyword,
          p_key: this.p_key,
        },
      }
      // 获取筛选批次号
      this.$get('/conf/getSelectConf', parameters).then((res) => {
        if (res.code === 10000) {
          console.log(res.data)
          this.batchList = res.data.Algae
        }
      })
    },
    cancel() {
      this.show = false
    },
  },
}
</script>

<style>
.main-content {
  padding: 1rem;
}
.yiyu-detail-title {
  float: right;
  font-weight: bold;
  margin: 0.5rem;
}
</style>
