<template>
  <div class="yiyu-detail">
    <template v-for="(item, index) in formOpts">
      <box-section
        :key="index"
        :title="item.title"
        color="#182A52"
        :border="'5px solid' + $mainColor"
        border-bottom="none"
        v-if="item.show != false"
      >
        <template #right v-if="item.rightSlot">
          <slot :name="item.rightSlot"></slot>
        </template>
        <template #content>
          <slot :name="item.topSlot" v-if="item.topSlot"></slot>
          <div
            v-for="(item1, index1) in item.child"
            :key="index1"
            class="yiyu-detail-item"
          >
            <div class="yiyu-detail-item-row" v-if="item1.show != false">
              <div class="label text-c7">{{ item1.label }}</div>
              <div style="margin-right: 4px">:</div>
              <div class="val" v-if="!item1.slot && !item1.bottomSlot">
                {{ item1.val || '暂无' }}
              </div>
              <div class="val" v-if="item1.slot">{{ item1.val }}</div>
              <div class="val" v-if="item1.bottomSlot">{{ item1.val }}</div>
              <div class="val" v-if="item1.slot">
                <slot :name="item1.slot"></slot>
              </div>
            </div>
            <div v-if="item1.bottomSlot && item1.show != false">
              <slot :name="item1.bottomSlot"></slot>
            </div>
          </div>
          <div v-if="item.slot">
            <slot :name="item.slot"></slot>
          </div>
        </template>
      </box-section>
    </template>
  </div>
</template>

<script>
import boxSection from '@/components/layout/boxSection.vue'
export default {
  name: 'yiyuDetail',
  props: {
    formOpts: {
      type: Array,
      default: () => [],
    },
    labelWidth: {
      type: String,
      default: '80px',
    },
  },
  components: {
    boxSection,
  },
}
</script>

<style lang="less" scoped>
.yiyu-detail {
  /deep/ .yiyu-box-section {
    padding: 0.5rem 0;
    // margin: 12px 0 0;
    border-radius: 8px;
    .title {
      margin: 10px 0 10px;
      .text {
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 700;
      }
    }

    .yiyu-detail-item {
      font-size: 1.1rem;
      font-weight: 500;

      &-row {
        padding: 0.3rem 1rem;
        display: flex;
      }

      .label {
        white-space: nowrap;
        text-align: justify;
        height: 1.25rem;
        line-height: 1.25rem;

        &::after {
          width: 100%;
          content: '';
          display: inline-block;
        }
      }

      .val {
        color: @titleColor;
        line-height: 1.25rem;
      }
    }

    &:first-of-type {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
}
</style>
