<template>
  <div class="yiyu-form">
    <van-form
      center="true"
      error-message-align="center"
      :show-error="false"
      @submit="onSubmit"
      input-align="center"
      ref="yiyuForm"
      @failed="onFailed"
    >
      <template v-for="(item, index) in formOpts">
        <boxSection
          v-if="item.show != false"
          :key="index"
          :title="item.title"
          color="#182A52"
          :border="'5px solid' + $mainColor"
          border-bottom="none"
        >
          <template #content>
            <div v-if="item.topSlot && item.show != false">
              <slot :name="item.topSlot"></slot>
            </div>
            <div v-for="(item1, index1) in item.child" :key="index1">
              <div v-if="item1.topSlot">
                <slot :name="item1.topSlot"></slot>
              </div>
              <!-- input-align="left" -->
              <!-- formType: 'textarea' -->
              <van-field
                class="textarea"
                v-if="item1.formType === 'textarea' && item1.show != false"
                :required="item1.required"
                clearable
                :autosize="true"
                show-word-limit
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                :rows="item1.rows || 1"
                type="textarea"
                clear-trigger="always"
                v-model="item1.value"
                :name="item1.name"
                :placeholder="
                  item1.placeholder
                    ? item1.placeholder +
                      '(限' +
                      (item1.maxlength || 300) +
                      '字)'
                    : ''
                "
                :rules="item1.rules ? item1.rules : []"
                @input="onInput($event, item1.name, item1.valueKey)"
                :maxlength="item1.maxlength || 300"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #extra v-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <!-- formType: 'number' -->
              <van-field
                v-else-if="item1.formType === 'number' && item1.show != false"
                :required="item1.required"
                clearable
                autosize
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                rows="1"
                type="number"
                clear-trigger="always"
                v-model="item1.value"
                :name="item1.name"
                :label="item1.label"
                :placeholder="item1.placeholder"
                :rules="item1.rules ? item1.rules : []"
                @input="onInput($event, item1.name, item1.valueKey)"
                maxlength="30"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #extra v-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <!-- 文件上传 -->
              <!-- formType: 'file' -->
              <van-field
                class="file-upload"
                v-else-if="item1.formType === 'file' && item1.show != false"
                :name="item1.name"
                :label="item1.label"
                :rules="item1.rules ? item1.rules : []"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #input>
                  <slot :name="item1.slot">
                    <!-- 上传文件的组件不写在这里，是因为值不好改变 -->
                  </slot>
                </template>
              </van-field>
              <!-- formType: 'radio' -->
              <van-field
                class="radio"
                v-else-if="item1.formType === 'radio' && item1.show != false"
                name="radio"
                :label="item1.label"
                :required="item1.required"
                clearable
                autosize
                :readonly="Boolean(item1.readonly)"
                :placeholder="item1.placeholder"
                :rules="item1.rules ? item1.rules : []"
              >
                <template #label>
                  <!-- <span> -->
                  {{ item1.label }}
                  <!-- </span> -->
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #left-icon> </template>
                <template #input>
                  <van-radio-group v-model="item1.value" direction="horizontal">
                    <!-- 支持数组格式 -->
                    <template v-if="Array.isArray(item1.opts)">
                      <van-radio
                        :name="item2.name"
                        v-for="(item2, index2) in item1.opts"
                        :key="index2"
                        @click="onInput(item2.name, item1.name, item1.valueKey)"
                        >{{ item2.label }}</van-radio
                      >
                    </template>
                    <!-- 支持对象格式 -->
                    <template v-else>
                      <van-radio
                        :name="key2"
                        v-for="(item2, key2, index2) in item1.opts"
                        :key="index2"
                        @click="onInput(key2, item1.name, item1.valueKey)"
                        >{{ item2 }}</van-radio
                      >
                    </template>
                  </van-radio-group>
                </template>
              </van-field>
              <!-- formType: 'select' -->
              <van-field
                v-else-if="item1.formType === 'select' && item1.show != false"
                :required="item1.required"
                readonly
                :clickable="item1.clickable != false"
                is-link
                arrow-direction="down"
                :value="item1.value"
                :name="item1.name"
                :label="item1.label"
                :placeholder="item1.placeholder"
                :rules="item1.rules"
                @click="item1.click"
              >
                <template #extra v-if="item1.extraSlot">
                  <slot :name="item1.extraSlot"></slot>
                </template>
              </van-field>
              <!-- 既支持输入也支持选择 -->
              <!-- formType: 'input-select' -->
              <van-field
                v-else-if="
                  item1.formType === 'input-select' && item1.show != false
                "
                :required="item1.required"
                v-model="item1.value"
                :name="item1.name"
                :label="item1.label"
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                :placeholder="item1.placeholder"
                :rules="item1.rules ? item1.rules : []"
                :type="item1.type"
                datetimeShow
                @input="
                  onInput($event, item1.name, item1.valueKey, item1.change)
                "
                @click-right-icon="item1.click"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #right-icon>
                  <van-icon name="arrow-down" />
                </template>
              </van-field>
              <!-- formType: 'input', 这是默认field -->
              <van-field
                v-else-if="item1.show != false"
                :required="item1.required"
                v-model="item1.value"
                :name="item1.name"
                clearable
                autosize
                :label="item1.label"
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                :placeholder="item1.placeholder"
                :rules="item1.rules ? item1.rules : []"
                :type="item1.type"
                datetimeShow
                @input="onInput($event, item1.name, item1.valueKey)"
                @click="textClick(item1.click)"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover
                    v-if="item1.tipSlot"
                    v-model="item1.tipSlot"
                  ></tip-popover>
                </template>
                <template #extra v-if="item1.slot">
                  <div class="extra-txt">{{ item1.extra }}</div>
                  <slot :name="item1.slot"></slot>
                </template>
                <template #extra v-else-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <div v-if="item1.bottomSlot && item1.show != false">
                <slot :name="item1.bottomSlot"></slot>
              </div>
            </div>
            <div v-if="item.slot && item.show != false">
              <slot :name="item.slot"></slot>
            </div>
          </template>
          <template #right v-if="item.titleRight">
            <slot :name="item.titleRight"></slot>
          </template>
        </boxSection>
      </template>

      <slot name="footer"></slot>
    </van-form>
  </div>
</template>

<script>
import tipPopover from '@/components/feedback/tipPopover.vue'
import boxSection from '@/components/layout/boxSection.vue'
export default {
  name: 'yiyuForm',
  props: {
    formOpts: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    _this: {
      required: true,
    },
  },
  components: {
    boxSection,
    tipPopover,
  },
  methods: {
    textClick(clickFun) {
      if (clickFun !== undefined) {
        clickFun()
      }
    },
    onSubmit(values) {
      this.$emit('submit', values)
    },
    onInput(val, key, valueKey = ['form'], fn) {
      if (typeof fn == 'function') {
        fn(val)
      }

      if (valueKey[0] == 'form') {
        this._this.form[key] = val
      } else {
        // 修改
        this._this[valueKey[0]][valueKey[1]][valueKey[2]] = val
      }
    },
    onFailed() {
      this.$toast.fail('请正确填写表单哦')
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-form {
  /deep/ .yiyu-box-section {
    padding: 0.5rem 0;
    margin: 12px 10px 0;
    border-radius: 8px;
    .title {
      margin: 20px 0 10px;
      &:first-of-type {
        margin-top: 0;
      }
      .text {
        font-size: 1.1rem;
        line-height: 24px;
        color: @titleColor;
        font-weight: 500;
      }
      .right {
        padding-right: 16px;
      }
    }
  }

  .extra-txt {
    margin-left: 16px;
  }

  /deep/ .van-cell:last-child::after,
  .van-cell--borderless::after {
    display: inline-block;
  }

  /deep/ .van-cell::after {
    border-bottom: none;
    border-top: 1px solid @lineColor;
  }
  // :colon="item1.colon"
  /deep/.van-cell__title.van-field__label {
    // text-align: justify;
    width: auto;
    height: 48px;
    line-height: 48px;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  /deep/.van-field__control.van-field__control--right {
    .Ellipsis;
  }

  /deep/ .van-field__control.van-field__control--center {
    .Ellipsis;
  }

  /deep/ .van-cell {
    // height: 64px;
    height: 48px;
    box-sizing: border-box;
    align-items: center;

    &.textarea {
      height: auto;
      align-items: flex-start;

      .van-field__body {
        background: rgba(@bgRgb, 0.3);
        // border: 1px solid @lineColor;
        min-height: 3rem;
        .van-field__control {
          padding: 0.5rem;
        }

        .van-field__control.van-field__control--center {
          overflow: visible;
          text-overflow: initial;
          white-space: normal;
          text-align: left;
        }
      }
    }
  }

  /deep/.yiyu-box-section {
    margin: 12px 0 0;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .yiyu-form-readonly {
    color: @textColor;
    opacity: 0.7;

    /deep/.van-field__control {
      color: @textColor;
    }
  }

  /deep/.radio.van-cell {
    height: auto;

    .van-radio-group.van-radio-group--horizontal {
      flex-basis: 100%;
      // padding-left: 10%;// 显示容易有问题
      box-sizing: border-box;
    }

    .van-radio.van-radio--horizontal {
      // width: 40%;
      // padding: 0.2rem;
      box-sizing: border-box;
    }
  }

  .file-upload {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  // /deep/.van-cell__title.van-field__label {
  // }
}
</style>
