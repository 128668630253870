<template>
  <div class="pb-20">
    <navbar tab-title="分配详情" />
    <div class="mx-4">
      <div class="mb-2">
        <span>需求</span>
      </div>
      <div class="list-item-box">
        <div
          class="list-item-box-item"
          v-for="field in requirementCardField"
          :key="field"
        >
          <div class="label text-justify-hack">{{ wordMap[field] }}</div>
          :
          <div class="content">
            {{ distributeCardValueComputed(field, RequestOne[field]) }}
          </div>
        </div>
      </div>
    </div>
    <van-divider />
    <div v-if="status == 2" class="flex justify-center pb-2">
      <button
        class="w-4/5 main-c border border-primary px-4 py-1 rounded"
        @click="handleEditAlgaDistribute"
      >
        您尚未完成分配, 点击继续分配
      </button>
    </div>
    <div class="mx-4">
      <!-- <transition-group name="fade"> -->
      <div
        class="flex flex-col mb-4"
        v-for="(distribution, idx) in distributionList"
        :key="idx"
      >
        <div class="flex justify-between mb-2">
          <span>分配操作</span>
          <handle-popver
            @edit="handleEditAlgaDistribute(distribution, idx)"
            @del="handleDelAlgaDistribute(distribution)"
          />
        </div>
        <div>
          <div class="list-item-box">
            <div
              class="list-item-box-item"
              v-for="field in distributeCardField"
              :key="field"
            >
              <div class="label text-justify-hack">{{ wordMap[field] }}</div>
              :
              <div class="content">
                {{ distributeCardValueComputed(field, distribution[field]) }}
              </div>
            </div>
          </div>
          <!-- 车间信息 -->
          <div v-for="batch in distribution.dist_list" :key="batch.ws_key">
            <div class="p-1">{{ batch.list[0].batch }}</div>
            <el-table :data="batch.list" border>
              <el-table-column
                prop="ws_name"
                label="池号"
                :formatter="ElFormatter"
              />
              <el-table-column
                prop="record_date"
                label="放入日期"
                :formatter="ElFormatter"
              />
              <el-table-column
                prop="assigned_amount"
                label="分配量"
                :formatter="ElFormatter"
              />
            </el-table>
          </div>
        </div>
      </div>
      <!-- </transition-group> -->
    </div>
  </div>
</template>

<script>
import algaMixin from './algaMixin'
import { decimalPointFormat } from '@/utils/number'
import Navbar from '@/components/navbar.vue'
import HandlePopver from '@/components/feedback/handlePopver.vue'

export default {
  name: 'algaDistributeRecordDetail',
  components: { Navbar, HandlePopver },
  mixins: [algaMixin],
  data() {
    return {
      RequestOne: {},
      basement: {},
      rr_key: '',
      distributionList: [],
      requirementCardField: ['mc_name', 'quantity', 'reque_day', 'nickname'],
      distributeCardField: [
        'total_distribute',
        'pool_count',
        'operate_man',
        'operate_time',
      ],
    }
  },
  computed: {
    wordMap({ algaListItemWordMap }) {
      return algaListItemWordMap.distribute
    },
    distributeCardValueComputed() {
      return (field, value) => {
        switch (field) {
          case 'total_distribute':
            return decimalPointFormat(value, 3) + 'L'
          case 'operate_time':
          case 'created_day':
            return this.$dayjs(value).format('YY/MM/DD HH:mm')
          case 'reque_day':
            return this.$dayjs(value).format('YY/MM/DD')
          default:
            return value
        }
      }
    },
    status({ RequestOne }) {
      return RequestOne.status || 0
    },
  },
  mounted() {
    const { basement } = this.$route.params
    this.basement = basement
  },
  activated() {
    this.rr_key = this.$route.query?.rr_key
    this.$get('/request/getRequestOne', {
      rr_key: this.rr_key,
    }).then((res) => {
      this.RequestOne = res.data
    })
    this.fetchDistribution()
  },
  methods: {
    fetchDistribution() {
      this.$get('/distribute/getDistributionOne', {
        rr_key: this.rr_key,
      }).then((res) => {
        this.distributionList = res.data
      })
    },
    getSignKey(distribution) {
      return distribution?.dist_list?.[0]?.list?.[0]?.sign_key
    },
    handleEditAlgaDistribute(distribution, idx) {
      const sign_key = this.getSignKey(distribution)
      this.$router.push({
        name: 'algaDistribute',
        params: {
          basement: this.basement,
          sign_key,
          cached: true,
          idx,
        },
      })
    },
    handleDelAlgaDistribute(distribution) {
      const { dist_list } = distribution
      if (dist_list.length <= 0) return
      const sign_key = this.getSignKey(distribution)
      if (sign_key) {
        this.$get('/distribute/delDistribution', {
          sign_key,
        })
          .then((res) => {
            this.$toast(res.msg)
            this.fetchDistribution()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    ElFormatter(row, column, cellvalue) {
      switch (column.property) {
        case 'ws_name':
          return `${row.ws_name}-${row.p_name}`
        case 'record_date':
          return this.$dayjs(cellvalue).format('YY-MM-DD')
        case 'assigned_amount':
          return cellvalue + 'L'
        default:
          return cellvalue
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './alga.less';
</style>
