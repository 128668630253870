<template>
  <van-dialog
    class="add-ws-popup"
    v-model="show"
    show-cancel-button
    @confirm="toSubmit"
    :before-close="beforeClose"
  >
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
    >
      <template slot="genePool">
        <van-button
          type="primary"
          size="mini"
          class="generate-btn"
          :disabled="generate"
          @click="genePool()"
          native-type="button"
        >
          生成
        </van-button>
      </template>
      <template slot="pool">
        <handle-tag
          :list.sync="p_list"
          @add="addPool"
          @del="delPool"
          :addable="true"
          :editable="true"
          :deletable="true"
          :addShow="generate"
        ></handle-tag>
      </template>
    </yiyu-form>
  </van-dialog>
</template>

<script>
import yiyuForm from '@/components/form.vue'
import handleTag from '@/components/tag/handleTag'
export default {
  name: 'addWs',
  props: {
    showVisible: {
      // sync
      type: Boolean,
      default: false,
    },
    data: {
      // sync 只负责接收值
      require: false,
    },
  },
  components: {
    yiyuForm,
    handleTag,
  },
  data() {
    return {
      thisAlias: this,
      show: false,
      form: {
        name: undefined, // 车间名称
        pool_num: undefined, // 池数
      },
      p_list: [], // 池
      generate: false,
    }
  },
  watch: {
    showVisible(val) {
      this.show = val
      if (val) {
        this.form = this.$options.data.call(this).form
        this.p_list = []
        this.generate = false
      }
    },
    show(val) {
      if (!val) {
        this.$emit('update:showVisible', val)
      }
    },
  },
  computed: {
    formOpts() {
      return [
        {
          title: '',
          slot: 'pool',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.name,
              name: 'name',
              label: '车间名称',
              placeholder: '请输入车间名称',
              rules: [{ required: true, message: '请输入车间名称' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input', // 默认
              value: this.form.pool_num || undefined,
              name: 'pool_num',
              type: 'number',
              label: '池数',
              placeholder: '请输入',
              rules: [{ required: false, message: '请输入' }],
              slot: 'genePool',
              readonly: this.generate,
            },
          ],
        },
      ]
    },
  },
  methods: {
    beforeClose(action, done) {
      switch (action) {
        case 'cancel':
          done()
          break
        case 'confirm':
          done(false)
          break
      }
    },
    onSubmit() {
      this.$emit('update:data', {
        name: this.form.name,
        p_list: this.p_list,
      })
      this.$emit('confirm')
      this.show = false
    },
    // 新增池
    addPool() {
      this.p_list.push({
        name: '待命名',
      })
      this.form['pool_num'] = Number(this.form['pool_num']) + 1
    },
    // 删除池
    delPool(index) {
      this.p_list.splice(index, 1)
      this.form['pool_num'] = Number(this.form['pool_num']) - 1
    },
    genePool() {
      if (this.form['pool_num'] > 0) {
        this.generate = true
        for (let i = 0; i < this.form['pool_num']; i++) {
          this.p_list.push({
            name: '#' + (i + 1),
          })
        }
      } else {
        this.$toast.fail('池数不能为空！')
      }
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
  },
}
</script>

<style lang="less" scoped>
.add-ws-popup.van-dialog {
  padding: 0;

  /deep/.van-dialog__content .yiyu-handle-tag {
    max-height: 50vh;
    overflow-y: auto;
  }
}
</style>
