/**
 *  适用于对象数组, 把数组转换成对象
 * @param {object[]} arr
 * @param {String} key
 * @return {Object} - 一个对象, 键为 数组中每个对象中key对应的值, 值为对象本身,
 * 对于重复的 key 在数组后面的会覆盖数组前面的
 */
export const ArrayObjKeyMap = (arr, key) =>
  arr.reduce((obj, acc) => {
    return {
      ...obj,
      [acc[key]]: acc,
    }
  }, {})

/**
 * 把字符串数组拼接成字符串
 * @param {String[]} arr
 * @param {String} symbol
 * @returns {String}
 */
export const ArrayToString = (arr, symbol = ',') =>
  arr
    .reduce((str, cur) => {
      if (cur) {
        return `${str}${symbol}${cur}`
      } else {
        return str
      }
    }, '')
    .slice(1)

export const ArrayToEmptyObj = (arr) =>
  arr.reduce((obj, cur) => ({ ...obj, [cur]: {} }), {})
