<template>
  <div class="pb-20">
    <yy-navbar tab-title="分配管理" />
    <YFilter
      :data="distributeFilterOptions"
      @search="handleSearch"
      @reset="handleFilterReset"
    >
      <YFilterItemCalendar
        slot="时间"
        type="range"
        :default-date="[
          this.$dayjs(param.start_day).$d,
          this.$dayjs(param.end_day).$d,
        ]"
      />
    </YFilter>
    <div class="mx-2 my-1">
      <button
        class="h-10 mx-1 px-4 rounded text-sm"
        :class="{
          'bg-primary bg-opacity-80 text-white border-none':
            singleDateSelected == date.value,
        }"
        v-for="date in singleDate"
        :key="date.label"
        @click="handleDayChange(date)"
      >
        {{ date.label }}
      </button>
    </div>
    <div class="mx-2 mb-2">
      <span>聚合</span>
      <scrollTag
        v-for="(scrollTag, idx) in scrollTags"
        class="flex"
        :key="idx"
        :opts="scrollTag.opts"
        :chosed.sync="scrollTag.checked"
        :single="true"
        @click="handleTagClick"
      />
    </div>
    <div class="row-between">
      <div></div>
      <download-excel
        :data="exportData"
        :fields="exportHeader"
        :name="exportFileName"
      >
        <div class="main-c pr-2">
          <van-icon name="upgrade" class="change-table-icon" />

          导出
        </div>
      </download-excel>
    </div>
    <el-table
      :data="list"
      @cell-click="handleCellClickMixin"
      :cell-style="cellStyleMixin"
      v-myLoad="loading"
    >
      <el-table-column
        v-for="prop in tableProps"
        :key="prop"
        :prop="prop"
        :label="wordMap[prop]"
        :width="tablePropsConfig[prop].width"
        :formatter="formatterMixin(prop)"
      />
    </el-table>
    <drag-btn slot-name="top" mode="app" :target="$refs.root" />
  </div>
</template>

<script>
import Vue from 'vue';

import JsonExcel from 'vue-json-excel';
import { List } from 'vant';
import algaMixin from './algaMixin';
import { filters } from './algaFilterPreset';
import ElTableMixin from '@/mixin/ElTableMixin';
import scrollTag from '@/components/scroll/tag.vue';
import { omitBy, isUndefined, has } from 'lodash-es';
import DragBtn from '@/components/button/dragBtn.vue';
import { YFilter, YFilterItemCalendar } from 'yiyu-vant-ui';
import { ArrayToString } from '@/utils/array';
import { decimalPointFormat } from '@/utils/number';
Vue.component('downloadExcel', JsonExcel);

export default {
  name: 'algaDistributeRecord',
  components: {
    YFilter,
    DragBtn,
    YFilterItemCalendar,
    scrollTag,
    [List.name]: List,
  },
  mixins: [algaMixin, ElTableMixin],
  data() {
    return {
      singleDate: [
        {
          label: '昨天',
          value: this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        },
        {
          label: '今天',
          value: this.$dayjs().format('YYYY-MM-DD'),
        },
        {
          label: '明天',
          value: this.$dayjs().add(1, 'day').format('YYYY-MM-DD'),
        },
        {
          label: '后天',
          value: this.$dayjs().add(2, 'day').format('YYYY-MM-DD'),
        },
      ],
      singleDateSelected: '',
      distributeFilterOptions: [
        {
          label: '时间',
          keyName: ['start_day', 'end_day'],
        },
        filters().distributeStatus('status'),
      ],
      param: {},
      count: 10,
      page: 1,
      list: [],
      tablePropsConfig: {
        reque_day: {
          width: '120',
          shoWhen: ['tag'],
          formatter: (row, column, cellValue) => {
            return cellValue;
          },
        },
        tag: {
          shoWhen: ['0'],
          formatter: (row, column, cellValue) =>
            cellValue == 1 ? '上午' : '下午',
        },
        mc_name: {
          shoWhen: ['sign'],
          width: '100',
        },
        quantity: {
          formatter: (row, column, cellValue) => cellValue + 'L',
        },
        total_distribute: {
          width: 120,
          formatter: (row, column, cellValue) =>
            decimalPointFormat(cellValue, 3) + 'L',
        },
        expecting_distribute: {
          width: '120',
          formatter: (row) =>
            decimalPointFormat(
              Number(row.quantity) - Number(row.total_distribute),
              3
            ) + 'L',
        },
        batch: {
          shoWhen: ['0'],
          width: '200',
          cellStyle: {
            color: this.$mainColor,
          },
          onClick: (e) => {
            this.handleBasementCardClick(e);
          },
          formatter: (row) => {
            const { batch_list } = row;
            const str = ArrayToString(batch_list, '、');
            return str ? str : '待分配';
          },
        },
      },
      scrollTags: {
        1: {
          checked: undefined,
          keyName: 'sign',
          opts: [
            {
              label: '需求基地',
              name: '1',
            },
          ],
        },
        2: {
          checked: undefined,
          keyName: 'tag',
          opts: [
            {
              label: '日',
              name: '3',
            },
            {
              label: '月',
              name: '2',
            },
            {
              label: '年',
              name: '1',
            },
          ],
        },
      },
      loading: false,
    };
  },
  computed: {
    exportData() {
      let list = JSON.parse(JSON.stringify(this.list));
      list.forEach((item) => {
        item.tag = item.tag == 1 ? '上午' : '下午';
        item.expecting_distribute =
          decimalPointFormat(
            Number(item.quantity) - Number(item.total_distribute),
            3
          ) + 'L';

        item.total_distribute =
          decimalPointFormat(item.total_distribute, 3) + 'L';
        item.quantity = item.quantity + 'L';
      });
      return list;
    },
    exportHeader() {
      let obj = {};

      this.tableProps.forEach((item) => {
        obj[this.wordMap[item]] = item;
      });

      return obj;
    },
    exportFileName() {
      return '分配管理列表';
    },
    wordMap() {
      return this.algaListItemWordMap.distribute;
    },
    distributeCardField() {
      return (status) => {
        switch (status) {
          case 2:
          case 3:
            return [
              'mc_name',
              'status',
              'quantity',
              'reque_day',
              'total_distribute',
              'count',
              'batch',
            ];
          // case 2 || '2':
          //   return ['mc_name', 'status', 'quantity', 'reque_day']
          default:
            return ['mc_name', 'status', 'quantity', 'reque_day'];
        }
      };
    },
    fieldWordComputed() {
      return (field, value) => {
        switch (field) {
          case 'quantity':
            return value + 'L';
          case 'status':
            return value == 1 ? '待分配' : value == 2 ? '部分分配' : '已分配';
          case 'count':
            return value + '池';
          case 'reque_day':
            return this.$dayjs(value).format('YY-MM-DD');
          default:
            return value;
        }
      };
    },
    gridBoxData({ distributeCardField, wordMap, fieldWordComputed }) {
      return (basement) =>
        distributeCardField(basement.status).map((field) => ({
          label: wordMap[field],
          value: fieldWordComputed(field, basement[field]),
          color: field == 'status' ? this.$mainColor : '',
        }));
    },
    // 抽离聚合逻辑
    tableProps({ tablePropsConfig }) {
      const _o = this.GroupAggregationTag();
      // 1. _o 有数据, 进行聚合
      if (Object.keys(_o).length > 0) {
        let showKes = [];
        for (const key in tablePropsConfig) {
          // 没有配置 shoWhen, 直接推入
          if (typeof tablePropsConfig[key].shoWhen == 'undefined') {
            showKes.push(key);
            continue;
          }
          tablePropsConfig[key].shoWhen.forEach((item) => {
            if (has(_o, item)) showKes.push(key);
          });
        }
        return showKes;
      }

      // end. 没有进行聚合时返回所有可看参数
      return Object.keys(tablePropsConfig);
    },
  },
  activated() {
    this.initData();
    this.getData()
      .then(({ data }) => {
        const { list } = data;
        this.list = list;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleDayChange(date) {
      this.singleDateSelected == date.value
        ? (this.singleDateSelected = '')
        : (this.singleDateSelected = date.value);
      this.getData({
        start_day: this.singleDateSelected,
      })
        .then(({ data }) => {
          this.list = data.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initData() {
      this.page = 1;
      this.list = [];
    },
    handleBasementCardClick(basement) {
      let name = 'algaDistributeRecordDetail';
      switch (basement.status) {
        case 1:
          {
            name = 'algaDistribute';
          }
          break;
        default:
          break;
      }
      this.$router.push({
        name,
        params: {
          basement,
          cached: true,
        },
        query: {
          rr_key: basement?.rr_key,
        },
      });
    },
    handleFilterReset() {
      this.initData();
      this.param = {};
      this.getData()
        .then(({ data }) => {
          const { list } = data;
          this.list = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSearch(e) {
      this.initData();
      this.param = Object.assign(this.param, e);
      this.getData()
        .then(({ data }) => {
          const { list } = data;
          this.list = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    GroupAggregationTag() {
      return omitBy(
        Object.values(this.scrollTags).reduce(
          (obj, acc) => ({ ...obj, [acc.keyName]: acc.checked }),
          {}
        ),
        isUndefined
      );
    },
    handleTagClick() {
      this.getData(this.GroupAggregationTag())
        .then(({ data }) => {
          const { list } = data;
          this.list = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData(params) {
      this.loading = true;
      const param = {
        // page: this.page,
        // count: this.count,
        ...this.param,
        ...params,
      };
      return this.$get('/distribute/getDistributionList', param);
    },
  },
};
</script>

<style lang="less" scoped>
@import './alga.less';
</style>
