<template>
  <div class="main relative" v-myLoad="isLoading">
    <yiyu-navbar :tab-title="tabTitle"> </yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      :form="form"
      @submit="onSubmit"
      ref="form"
    >
      <template #containerCount>
        <div class="containerCount" v-if="form.containerCount">
          {{ containerName }}：{{ containerCount }}
        </div>
      </template>
      <template #empty>
        <div class="hr"></div>
      </template>
    </yiyu-form>
    <yiyu-picker
      v-if="isShowPicker"
      :show.sync="isShowPicker"
      :columns="Columns"
      value-key="name"
      @confirm="confirmPicker($event)"
      @cancel="cancelPicker()"
    >
    </yiyu-picker>

    <van-popup v-model="showDatetimePicker" round position="bottom">
      <van-datetime-picker
        type="date"
        v-model="record_date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
    <van-popup
      class="popup"
      v-model="showPopup"
      round
      position="bottom"
      closeable
    >
      <select-tag
        v-if="type == 'base'"
        :opts="baseOpts"
        :single="true"
        v-model="form.baseName"
        @change="baseChange"
      ></select-tag>

      <select-tag
        v-if="type == 'farm'"
        :opts="farmOpts"
        :single="false"
        v-model="form.farmName"
      ></select-tag>
      <fixed-btn
        v-if="type == 'farm'"
        btnTxt="确认"
        @click="farmChange"
      ></fixed-btn>
    </van-popup>

    <fixed-btn
      btnTxt="保存"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
import selectTag from '@/components/tag/selectTag'
import { formatTime } from '@/utils/date'

export default {
  name: 'algaCopy',
  components: {
    yiyuForm,
    yiyuPicker,
    fixedBtn,
    yiyuNavbar,
    selectTag,
  },
  watch: {
    'form.farmName': {
      deep: true,
      handler() {
        let list = []

        this.form.farmName.map((item) => {
          list.push({
            name: item,
          })
        })
        this.checkFarm = list
      },
    },
    'form.baseName': {
      deep: true,
      handler(newVal) {
        if (!newVal[0]) {
          this.form.farmName = []
        }
      },
    },

    'form.breed_day': {
      deep: true,
      handler: function () {
        if (this.form.ori_stage === '三级') {
          this.form.containerCount =
            this.list?.dataList[this.ori_stage_key]?.[this.form.breed_day]
              ?.count || '1'
        } else {
          let obj =
            this.list?.dataList[this.ori_stage_key || 1][
              this.form.container || 1
            ]?.detail[this.form.breed_day] || 1

          this.form.containerCount = obj
        }
      },
    },
  },
  computed: {
    dateStage() {
      let date = formatTime(this.record_date).slice(0, 10)
      return date
    },
    formOpts() {
      let _this = this
      let opts = [
        {
          child: [
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.dateStage,
              name: 'dateStage',
              label: '操作日期',
              placeholder: '',
              click() {
                _this.showDatetimePicker = true
              },
              rules: [{ required: true, message: '请选择默认日期' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.form.ori_stage,
              name: 'ori_stage',
              label: '当前阶段',
              placeholder: '',
              click() {
                _this.type = 'ori_stage'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择当前阶段' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.container,
              name: 'container',
              label: '当前容器',

              placeholder: '',
              click() {
                _this.type = 'container'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择容器计量单位' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.breed_day,
              name: 'breed_day',
              label: '培育天数',
              extra: '天',
              click() {
                _this.type = 'breed_day'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请输入培育天数' }],
              bottomSlot: 'containerCount',
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.adj_stage,
              name: 'adj_stage',
              label: '阶段',
              placeholder: '请选择要复制的阶段',
              click() {
                _this.type = 'adj_stage'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择要复制的阶段' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.newContainer,
              name: 'newContainer',
              label: '容器',
              placeholder: '请选择容器',
              click() {
                _this.type = 'newContainer'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择要调整的阶段' }],
            },
            {
              show: this.form.newContainer,
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.adj_day,
              name: 'adj_day',
              label: '培育天数',
              placeholder: '请选择培育天数',
              click() {
                _this.type = 'adj_day'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
            },
            {
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.container_cnt,
              name: 'container_cnt',
              label: '容器数',
              placeholder: '请输入复制后使用的容器数量',
              rules: [
                { required: true, message: '请输入复制后使用的容器数量' },
              ],
            },
          ],
        },
      ]
      return opts
    },

    // eslint-disable-next-line vue/return-in-computed-property
    ori_stage_key({ form }) {
      switch (form.ori_stage) {
        case '保种':
          return '1'
        case '一级':
          return '2'
        case '二级':
          return '3'
        case '三级':
          return '4'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    adj_stage_key({ form }) {
      switch (form.adj_stage) {
        case '保种':
          return '1'
        case '一级':
          return '2'
        case '二级':
          return '3'
        case '三级':
          return '4'
      }
    },
    avg_density() {
      let density = 0
      this.pool_group.forEach((item) => {
        density += parseInt(item.density)
      })
      if (window.isNaN(density / this.p_keyGroup.length)) {
        return ''
      }
      return (density / this.p_keyGroup.length).toFixed(2) || ''
    },
    poolQuantity() {
      let all = 0
      this.pool_group.forEach((item) => {
        all += parseInt(item.quantity)
      })
      return all / 1 || ''
    },
    containerQuantity() {
      let count = ''
      if (this.adj_stage_key) {
        let list = this.list['Adj_container'][this.adj_stage_key]
        Array.isArray(list) &&
          list.forEach((item) => {
            if (item.name == this.form.newContainer) {
              count =
                item.capacity * this.form.container_cnt * this.form.density
            }
          })
      }
      return count
    },
    tabTitle() {
      return this.list.batch || '调整表单'
    },

    minDate() {
      return new Date(parseInt(new Date().getFullYear()) - 5, 0, 1)
    },
    maxDate() {
      return new Date(parseInt(new Date().getFullYear()) + 5, 10, 1)
    },
    containerCount() {
      // return getDiffDay(new Date(), this.record_date) + 1 > this.breed_day
      //   ? 0
      //   : this.form.containerCount + '个'
      return this.form.containerCount + '个'
    },
    baseOpts() {
      let list = []
      let obj
      for (let item in this.baseList) {
        obj = {
          val: item,
          key: item,
        }
        list.push(obj)
      }

      return list
    },
    Adj_container() {
      if (this.adj_stage_key == '4' && this.form.adj_container == '池') {
        return ''
      }
      return this.list?.Adj_container[this.adj_stage_key][
        this.form.newContainerKey
      ]?.key
    },
    c_key() {
      if (this.ori_stage_key == '4' && this.form.ori_container == '池') {
        return ''
      }
      return this.list?.dataList[this.ori_stage_key][this.form.container]?.key
    },
  },
  data() {
    return {
      Columns: [],
      list: {},

      checkFarm: [],
      containerName: '容器数量',
      record_date: new Date(),
      showDatetimePicker: false,
      btnLoading: false,
      isLoading: false,
      isShowPicker: false,
      farmOpts: [],
      type: '',
      isArrowShow: false,
      thisAlias: this,
      disabled: false,
      algae_key: undefined,
      popupShow: false,
      activeName: [],
      isFarm: false,
      currentIndex: '',
      showPopup: false,
      p_keyGroup: [], //所选中的数组
      pool_group: [],
      pool_data: {},
      ori_stage_list: [],
      adj_stage_list: [],
      baseList: {},
      pool_active: [],
      form: {
        algae_key: '', //藻类批次key
        ori_stage: '', //当前阶段
        breed_day: '', //是培育天数
        adjust_cnt: '', //调整数量
        containerCount: '',
        loss: '', //损耗数量
        container: '',
        adj_stage: '', //调整阶段
        newContainer: '',
        adj_container: '', //调整容器key
        container_cnt: '', //新容器数
        density: '', //密度
        quantity: '', //数量
        //升到三级时
        baseName: [],
        farmName: [],
        newContainerKey: '',
        adj_day: undefined,
      },
      farmListAll: {},
    }
  },
  mounted() {
    this.thisAlias = this
    console.log(this.$route.query)
    if (this.$route.query) {
      let query = this.$route.query
      this.algae_key = query.algae_key
      this.getData()
    }
  },
  methods: {
    ColumnsList() {
      switch (this.type) {
        case 'ori_stage':
          this.Columns = []
          this.ori_stage_list.forEach((item) => {
            this.Columns.push(this.keyToValue(item))
          })
          break
        case 'container':
          this.Columns = Object.keys(this.list.dataList[this.ori_stage_key])

          break

        case 'breed_day':
          {
            let obj =
              this.list.dataList[this.ori_stage_key][this.form.container]
                ?.detail

            this.Columns = Object.keys(obj)
            console.log(this.Columns)
          }
          break

        case 'adj_stage':
          this.Columns = []
          this.adj_stage_list.forEach((item) => {
            this.Columns.push(this.keyToValue(item))
          })
          break
        case 'newContainer': {
          let list = this.list['Adj_container'][this.adj_stage_key]
          let newList = []
          console.log(list)
          Array.isArray(list) &&
            list.forEach((item) => {
              if (item.name != '池') {
                newList.push(item?.name)
              }
            })

          this.Columns = newList

          break
        }

        case 'adj_day': {
          let list = this.list['Adj_container'][this.adj_stage_key]
          let newList = []

          Array.isArray(list) &&
            list.forEach((item, index) => {
              if (index == this.form.newContainerKey) {
                let j = 1
                for (let i = 0; i < item.usage_day; i++) {
                  newList.push(j)
                  j++
                }
              }
            })
          this.Columns = newList
          break
        }
      }
    },
    confirmPicker(e) {
      switch (this.type) {
        case 'ori_stage':
          this.form.ori_stage = e[0]
          if (e[0] === '三级') {
            this.containerName = '池数量'
          } else {
            this.containerName = '容器数量'
          }
          this.form.container = ''
          this.form.breed_day = ''
          this.form.containerCount = ''
          break
        case 'container':
          this.form.container = e[0]
          this.form.breed_day = ''
          this.form.containerCount = ''

          break
        case 'breed_day':
          this.form.breed_day = e[0]
          break
        case 'adj_stage':
          this.form.adj_stage = e[0]
          if (e[0] !== '三级') {
            this.form.farmName = []
          }
          this.form.newContainer = ''
          this.form.newContainerKey = ''
          break
        case 'newContainer':
          this.form.newContainer = e[0]
          this.form.newContainerKey = e[1]
          break
        case 'adj_day':
          this.form.adj_day = e[0]
          break
      }
      this.isShowPicker = false
    },
    farmChange() {
      console.log(this.farmListAll)
      this.farmListAll = this.baseList[this.form.baseName[0]] || []
      this.showPopup = false
    },

    baseChange() {
      this.farmOpts = []
      for (let key in this.baseList[this.form.baseName[0]]) {
        let obj = {
          val: key,
          key: key,
        }

        this.farmOpts.push(obj)
      }
      this.type = 'farm'
    },
    keyToValue(item) {
      switch (item) {
        case '1':
          return '保种'
        case 1:
          return '保种'
        case '2':
          return '一级'
        case 2:
          return '一级'
        case '3':
          return '二级'
        case 3:
          return '二级'
        case '4':
          return '三级'
        case 4:
          return '三级'
      }
    },

    getData() {
      this.baseList = this.list.pool_data
      this.isLoading = true
      this.$get('/algae/getEditData', {
        algae_key: this.algae_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data
          this.ori_stage_list = []
          this.adj_stage_list = []
          for (let item in res.data.dataList) {
            this.ori_stage_list.push(item)
          }
          for (let item in res.data.Adj_container) {
            this.adj_stage_list.push(item)
          }
          this.baseList = this.list.pool_data
          let last_data = res.data?.last_data

          this.record_date =
            (last_data.record_date && new Date(last_data.record_date)) ||
            this.record_date
          this.form.breed_day = last_data.cur_day || ''
          this.form.ori_stage = this.keyToValue(last_data.cur_stage) || ''
          this.form.container = last_data.cur_container || ''

          this.form.adj_stage = this.keyToValue(last_data.adj_stage) || ''
          this.form.newContainer = last_data.new_container || ''
        }
      })
    },

    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    cancelPicker() {
      this.isShowPicker = false
    },
    dateConfirm() {
      this.showDatetimePicker = false
    },
    dateCancel() {
      this.showDatetimePicker = false
    },

    onSubmit() {
      let params = {
        record_date: this.dateStage,

        algae_key: this.algae_key, //藻类批次key
        ori_stage: this.ori_stage_key, //当前阶段
        c_key: this.c_key, //容器key

        breed_day: this.form.breed_day, //是培育天数

        adj_stage: this.adj_stage_key, //调整阶段
        adj_container: this.Adj_container, //调整容器key
        container_cnt: this.form.container_cnt, //新容器数

        adj_day: this.form.adj_day,
      }
      this.btnLoading = true
      this.$post('/algae/copy', params).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('保存成功')
          this.nextShow = true
          this.$go(-1, this)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.containerCount {
  margin: 0.7rem 1rem;
  font-weight: bold;
}
/deep/.hr {
  width: 100%;
  border-bottom: 0.6rem solid #eee;
}
.pool_detail {
  background-color: #eee;
  padding: 0.7rem;
  border: 1 solid #eee;
  border-radius: 0.3rem;
  margin: 0.5rem auto;
}
</style>
