<template>
  <div class="yiyu-list mt-4">
    <div class="mx-4 mb-4" v-for="(item, idx) in data" :key="item.ac_key">
      <grid-box :value="gridBoxData(item)" />
      <div
        class="expend-card"
        @click.stop="handleCardExpend(idx)"
        v-if="item.pool_data"
      >
        池信息
        <van-icon :name="poolInfoShow === idx ? 'arrow-up' : 'arrow-down'" />
      </div>
      <!-- <van-divider v-else>暂无池信息</van-divider> -->
      <CollapseTransition>
        <div v-if="item.pool_data" v-show="poolInfoShow === idx">
          <div class="wordspace">
            车间:
            <check-button-group
              class="radio"
              :is-check="workspacePoolKeys()[checkboxCurrentCheck]"
              :list="workspaceCheckbox(item.pool_data)"
              @check="handleCheck"
            />
          </div>
          <div class="wordspace-info">
            <div class="wordspace-info-title">池信息</div>
            <el-table
              style="padding-right: 1rem; width: 100%"
              :data="tableData"
              stripe
            >
              <el-table-column prop="pool_name" label="池名" width="100" />
              <el-table-column label="状态" width="100">
                <template slot-scope="scope">
                  {{ tableItemStatus(scope.row.state) }}
                </template>
              </el-table-column>
              <el-table-column label="数量" width="100">
                <template slot-scope="scope">
                  {{ tableItemNumber(scope.row.number) }}
                </template>
              </el-table-column>
              <el-table-column prop="ass_name" label="分配" width="100">
                <template slot-scope="scope">{{
                  scope.row['assigned_amount']
                }}</template>
              </el-table-column>
              <el-table-column prop="ass_date" label="分配日期" width="100">
                <template slot-scope="scope">{{
                  scope.row['operation_date'] &&
                  scope.row['operation_date'].slice(0, 10)
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </CollapseTransition>
    </div>
  </div>
</template>

<script>
import algaMixin from './algaMixin'
import CollapseTransition from '@/components/seeMore/collapse'
import checkButtonGroup from '@/components/checkButtonGroup.vue'
import GridBox from '@/components/layout/gridBox.vue'

const algaListDetailCurCard = {
  field: [
    'stage',
    'breed_date',
    'container',
    'record_date',
    'c_number',
    'loss_num',
  ],
}

export default {
  props: {
    data: Array,
    algaeKey: String,
  },
  components: {
    CollapseTransition,
    checkButtonGroup,
    GridBox,
  },
  mixins: [algaMixin],
  data() {
    return {
      poolInfoShow: -1, // the index of current data which showing poll
      algaListDetailCurCard,
      checkboxCurrentCheck: 0, // the index of checking workshop
    }
  },
  computed: {
    workspaceCheckbox() {
      return (workspaces) =>
        Object.keys(workspaces).map((workspace) => {
          return {
            key: workspace,
          }
        })
    },
    tableData({ poolInfoShow, checkboxCurrentCheck }) {
      return poolInfoShow === -1
        ? []
        : this.workspacePool(poolInfoShow, checkboxCurrentCheck)
    },
    tableItemStatus() {
      return (status) => {
        switch (status) {
          case 1:
            return '完结'
          default:
            return '培育中'
        }
      }
    },
    tableItemNumber() {
      return (number) => `${number || 0}L`
    },
    listItemComputed() {
      return (field, value) => {
        switch (field) {
          case 'record_date':
            return this.$dayjs(value).format('YY-MM-DD')
          case 'stage':
            return this.mixinStageMap[value]
          default:
            return value
        }
      }
    },
    gridBoxData({ algaListDetailCurCard, algaListItemWordMap }) {
      return (data) => {
        return algaListDetailCurCard.field.map((field) => ({
          label: algaListItemWordMap[field],
          value: this.listItemComputed(field, data[field]),
          show: data[field],
        }))
      }
    },
  },
  methods: {
    workspacePool(worksaceIndex, poolIndex) {
      const pool_data = this.data[worksaceIndex]?.pool_data
      if (typeof poolIndex !== 'undefined') {
        const pool_name = Object.keys(pool_data)[poolIndex]
        return pool_data?.[pool_name]
      } else {
        return pool_data
      }
    },
    workspacePoolKeys() {
      return this.poolInfoShow !== -1
        ? Object.keys(this.workspacePool(this.poolInfoShow))
        : []
    },
    handleCardExpend(idx) {
      if (this.poolInfoShow === idx) {
        this.poolInfoShow = -1
        return
      }

      this.poolInfoShow = idx
      // if (this.data[idx]?.pool_data) {
      //   this.checkboxCurrentCheck = Object.keys(this.data[idx]?.pool_data)[0]
      // }
    },
    handleCheck(e) {
      const idx = this.workspacePoolKeys().indexOf(e)
      if (idx !== -1) this.checkboxCurrentCheck = idx
    },
  },
}
</script>

<style lang="less" scoped>
@import './alga.less';

.expend-card {
  color: @mainColor;
  margin-top: 0.35rem;
}

.wordspace {
  display: flex;
  align-items: center;
}

.wordspace-info {
  .wordspace-info-title {
    font-weight: 700;
  }
  .wordspace-info-table {
    margin-right: 1rem;
  }
}
</style>
