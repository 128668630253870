<template>
  <div class="main relative" v-myLoad="isLoading">
    <yiyu-navbar :tab-title="tabTitle"> </yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      :form="form"
      @submit="onSubmit"
      ref="form"
    >
      <template #containerCount>
        <div class="containerCount" v-if="form.containerCount">
          {{ containerName }}：{{ containerCount }}
        </div>
      </template>
      <template #empty>
        <div class="hr"></div>
      </template>
      <template #farm>
        <van-collapse v-model="activeName">
          <template v-if="form.farmName.length > 0">
            <van-checkbox-group v-model="checkboxGroup">
              <van-collapse-item
                v-for="(item, index) in checkFarm"
                :key="index"
                :name="index"
              >
                <template #title>
                  <div class="row-between">
                    <van-checkbox
                      :name="index"
                      class="pool-handle"
                      @click="wsChosedChange(item, index)"
                      >{{ item.name }}
                    </van-checkbox>
                    <div>
                      <van-icon name="arrow-down" v-show="!item.isCheck" />
                      <van-icon name="arrow-up" v-show="item.isCheck" />
                    </div>
                  </div>
                </template>
                <template #right-icon>
                  <van-icon
                    class="delFarm"
                    name="delete-o"
                    @click.stop.native="delFarm(item.name)"
                  />
                </template>
                <van-collapse v-model="pool_active">
                  <van-checkbox-group v-model="p_keyGroup">
                    <div
                      v-for="(item1, index1) in farmListAll[item.name]"
                      :key="item1.p_key"
                    >
                      <van-collapse-item :name="index1">
                        <template #title>
                          <div class="row-between">
                            <!-- @click.stop.native="() => {}" -->
                            <van-checkbox :name="item1.p_key">{{
                              item1.name
                            }}</van-checkbox>
                            <div>查看详情</div>
                          </div>
                        </template>

                        <div class="pool_detail">
                          <data-field :dataObj="getDataObj(item1)"></data-field>
                          <van-field
                            v-model="pool_data[item1.p_key].density"
                            type="number"
                            label="密度"
                            placeholder="请输入池中藻类密度"
                            @input="getPool"
                          ></van-field>
                          <van-field
                            v-model="pool_data[item1.p_key].quantity"
                            type="number"
                            label="数量"
                            placeholder="请输入池中藻类数量"
                            @input="getPool"
                          >
                            <template #extra> L </template>
                          </van-field>
                        </div>
                      </van-collapse-item>
                    </div>
                  </van-checkbox-group>
                </van-collapse>
              </van-collapse-item>
            </van-checkbox-group>
          </template>
        </van-collapse>
      </template>
    </yiyu-form>
    <yiyu-picker
      v-if="isShowPicker"
      :show.sync="isShowPicker"
      :columns="Columns"
      value-key="name"
      @confirm="confirmPicker($event)"
      @cancel="cancelPicker()"
    >
    </yiyu-picker>

    <van-popup v-model="showDatetimePicker" round position="bottom">
      <van-datetime-picker
        type="date"
        v-model="record_date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
    <van-popup
      class="popup"
      v-model="showPopup"
      round
      position="bottom"
      closeable
    >
      <select-tag
        v-if="type == 'base'"
        :opts="baseOpts"
        :single="true"
        v-model="form.baseName"
        @change="baseChange"
      ></select-tag>

      <select-tag
        v-if="type == 'farm'"
        :opts="farmOpts"
        :single="false"
        v-model="form.farmName"
      ></select-tag>
      <fixed-btn
        v-if="type == 'farm'"
        btnTxt="确认"
        @click="farmChange"
      ></fixed-btn>
    </van-popup>

    <fixed-btn
      btnTxt="保存"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
import selectTag from '@/components/tag/selectTag'
import { formatTime, getDiffDay } from '@/utils/date'
import DataField from '@/components/layout/dataField.vue'

export default {
  name: 'algaAdjust',
  components: {
    yiyuForm,
    yiyuPicker,
    fixedBtn,
    yiyuNavbar,
    selectTag,
    DataField,
  },
  watch: {
    p_keyGroup() {
      this.getPool()
    },
    'form.farmName': {
      deep: true,
      handler() {
        let list = []

        this.form.farmName.map((item) => {
          list.push({
            name: item,
          })
        })
        this.checkFarm = list
      },
    },
    'form.baseName': {
      deep: true,
      handler(newVal) {
        if (!newVal[0]) {
          this.form.farmName = []
        }
      },
    },

    'form.breed_day': {
      deep: true,
      handler: function () {
        if (this.form.ori_stage === '三级') {
          this.form.containerCount =
            this.list?.dataList[this.ori_stage_key]?.[this.form.breed_day]
              ?.count || '1'
        } else {
          let obj =
            this.list?.dataList[this.ori_stage_key || 1][
              this.form.container || 1
            ]?.detail[this.form.breed_day] || 1
          console.log(obj)
          this.form.containerCount = obj
        }
      },
    },
  },
  computed: {
    dateStage() {
      let date = formatTime(this.record_date).slice(0, 10)
      return date
    },
    formOpts() {
      let _this = this
      let opts = [
        {
          child: [
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.dateStage,
              name: 'dateStage',
              label: '操作日期',
              placeholder: '',
              click() {
                _this.showDatetimePicker = true
              },
              rules: [{ required: true, message: '请选择默认日期' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.form.ori_stage,
              name: 'ori_stage',
              label: '当前阶段',
              placeholder: '',
              click() {
                _this.type = 'ori_stage'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择当前阶段' }],
            },
            {
              show: this.form.ori_stage,
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.container,
              name: 'container',
              label: '当前容器',
              placeholder: '',
              click() {
                _this.type = 'container'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择容器计量单位' }],
            },
            {
              show: this.form.container || this.form.ori_stage == '三级',
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.breed_day,
              name: 'breed_day',
              label: '培育天数',
              extra: '天',
              click() {
                _this.type = 'breed_day'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请输入培育天数' }],
              bottomSlot: 'containerCount',
            },

            {
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.adjust_cnt,
              name: 'adjust_cnt',
              label: '需调整数',
              disabled:
                getDiffDay(new Date(), this.record_date) + 1 > this.breed_day,
              clickable:
                getDiffDay(new Date(), this.record_date) + 1 <= this.breed_day,
              placeholder: '请输入需调整容器数量',
              extra: '个',
              rules: [{ required: true, message: '请输入需调整容器数量' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              value: this.form.loss,
              name: 'loss',
              label: '淘汰数量',
              placeholder: '请输入容器淘汰数量',
              extra: '个',
              bottomSlot: 'empty',
            },

            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.adj_stage,
              name: 'adj_stage',
              label: '调整阶段',
              placeholder: '请选择要调整的阶段',
              click() {
                _this.type = 'adj_stage'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择要调整的阶段' }],
            },
            {
              show: this.form.adj_stage,
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.newContainer,
              name: 'newContainer',
              label: '新容器',
              placeholder: '请选择新容器',
              click() {
                _this.type = 'newContainer'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择要调整的阶段' }],
            },
            {
              show: this.form.newContainer != '池',
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.container_cnt,
              name: 'container_cnt',
              label: '新容器数',
              placeholder: '请输入升级后使用的容器数量',

              rules: [
                { required: true, message: '请输入升级后使用的容器数量' },
              ],
            },
            {
              show: this.form.newContainer == '池',
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.baseName[0],
              name: 'baseName',
              label: '选择基地',
              placeholder: '请选择基地',
              click() {
                _this.showPopup = true
                _this.type = 'base'
              },
              rules: [{ required: true, message: '请选择基地' }],
            },
            {
              show: this.form.newContainer == '池',
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.farmName.toString(),
              name: 'farmName',
              label: '选择车间',
              placeholder: '请选择车间',
              click() {
                _this.showPopup = true

                _this.type = 'farm'
              },
              rules: [{ required: true, message: '请选择车间' }],
            },
            {
              show: this.form.newContainer && this.form.newContainer != '池',
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.adj_day,
              name: 'adj_day',
              label: '培育天数',
              placeholder: '请选择培育天数',
              click() {
                _this.type = 'adj_day'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
            },
            {
              show: this.form.newContainer != '池',
              required: false,
              colon: true,
              formType: 'input',
              value: this.form.density,
              name: 'density',
              label: '密度',
              placeholder: '请输入藻在新容器中的密度',
            },
            {
              show: this.form.newContainer != '池',
              required: false,
              colon: true,
              formType: 'input',
              readonly: true,
              value: this.containerQuantity,
              name: 'containerQuantity',
              placeholder: '自动输入',
              label: '数量',
            },
          ],
        },
        {
          slot: 'farm',
        },
        {
          show: this.form.newContainer == '池',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input',
              readonly: true,
              value: this.p_keyGroup.length,
              name: 'pool_cnt',
              label: '池数',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              readonly: true,
              value: this.avg_density,
              name: 'avg_density',
              label: '平均密度',
            },
            {
              required: false,
              colon: true,
              formType: 'input',

              readonly: true,
              value: this.poolQuantity,
              name: 'quantity',
              label: '数量',
            },
          ],
        },
      ]
      return opts
    },

    // eslint-disable-next-line vue/return-in-computed-property
    ori_stage_key({ form }) {
      switch (form.ori_stage) {
        case '保种':
          return '1'
        case '一级':
          return '2'
        case '二级':
          return '3'
        case '三级':
          return '4'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    adj_stage_key({ form }) {
      switch (form.adj_stage) {
        case '保种':
          return '1'
        case '一级':
          return '2'
        case '二级':
          return '3'
        case '三级':
          return '4'
      }
    },
    avg_density() {
      let density = 0
      this.pool_group.forEach((item) => {
        density += parseInt(item.density)
      })
      if (window.isNaN(density / this.p_keyGroup.length)) {
        return ''
      }
      return (density / this.p_keyGroup.length).toFixed(2) || ''
    },
    poolQuantity() {
      let all = 0
      this.pool_group.forEach((item) => {
        all += parseInt(item.quantity)
      })
      return all / 1 || ''
    },
    containerQuantity() {
      let count = ''
      if (this.adj_stage_key) {
        let list = this.list['Adj_container'][this.adj_stage_key]
        Array.isArray(list) &&
          list.forEach((item) => {
            if (item.name == this.form.newContainer) {
              count =
                item.capacity * this.form.container_cnt * this.form.density
            }
          })
      }
      return count
    },
    tabTitle() {
      return this.list.batch || '调整表单'
    },

    minDate() {
      return new Date(parseInt(new Date().getFullYear()) - 5, 0, 1)
    },
    maxDate() {
      return new Date(parseInt(new Date().getFullYear()) + 5, 10, 1)
    },
    containerCount() {
      // return getDiffDay(new Date(), this.record_date) + 1 > this.breed_day
      //   ? 0
      //   : this.form.containerCount + '个'
      return this.form.containerCount + '个'
    },
    baseOpts() {
      let list = []
      let obj
      for (let item in this.baseList) {
        obj = {
          val: item,
          key: item,
        }
        list.push(obj)
      }

      return list
    },
    Adj_container() {
      if (this.adj_stage_key == '4' && this.form.adj_container == '池') {
        return ''
      }
      return this.list?.Adj_container[this.adj_stage_key][
        this.form.newContainerKey
      ]?.key
    },
    c_key() {
      if (this.ori_stage_key == '4' && this.form.ori_container == '池') {
        return ''
      }
      return this.list?.dataList[this.ori_stage_key][this.form.container]?.key
    },
  },
  data() {
    return {
      Columns: [],
      list: {},
      checkboxGroup: [],
      checkFarm: [],
      containerName: '容器数量',
      record_date: new Date(),
      showDatetimePicker: false,
      btnLoading: false,
      isLoading: false,
      isShowPicker: false,
      farmOpts: [],
      type: '',
      isArrowShow: false,
      thisAlias: this,
      disabled: false,
      algae_key: undefined,
      popupShow: false,
      activeName: [],
      isFarm: false,
      currentIndex: '',
      showPopup: false,
      p_keyGroup: [], //所选中的数组
      pool_group: [],
      pool_data: {},
      ori_stage_list: [],
      adj_stage_list: [],
      baseList: {},
      pool_active: [],
      form: {
        algae_key: '', //藻类批次key
        ori_stage: '', //当前阶段
        breed_day: '', //是培育天数
        adjust_cnt: '', //调整数量
        containerCount: '',
        loss: '', //损耗数量
        container: '',
        adj_stage: '', //调整阶段
        newContainer: '',
        adj_container: '', //调整容器key
        container_cnt: '', //新容器数
        density: '', //密度
        quantity: '', //数量
        //升到三级时
        baseName: [],
        farmName: [],
        newContainerKey: '',
        adj_day: undefined,
      },
      farmListAll: {},
    }
  },
  mounted() {
    this.thisAlias = this
    console.log(this.$route.query)
    if (this.$route.query) {
      let query = this.$route.query
      this.algae_key = query.algae_key
      this.getData()
    }
  },
  methods: {
    ColumnsList() {
      switch (this.type) {
        case 'ori_stage':
          this.Columns = []
          this.ori_stage_list.forEach((item) => {
            this.Columns.push(this.keyToValue(item))
          })
          break
        case 'container':
          this.Columns = Object.keys(this.list.dataList[this.ori_stage_key])

          if (
            this.form.ori_stage == '三级' &&
            this.list?.cur_pool.length != 0
          ) {
            this.Columns.push('池')
          }
          break

        case 'breed_day':
          if (this.form.ori_stage == '三级' && this.form.container == '池') {
            this.Columns = Object.keys(this.list.cur_pool)
          } else {
            let obj =
              this.list.dataList[this.ori_stage_key][this.form.container]
                ?.detail
            this.Columns = Object.keys(obj)
          }
          break

        case 'adj_stage':
          this.Columns = []
          this.adj_stage_list.forEach((item) => {
            this.Columns.push(this.keyToValue(item))
          })
          break
        case 'newContainer': {
          let list = this.list['Adj_container'][this.adj_stage_key]
          let newList = []
          console.log(list)
          Array.isArray(list) &&
            list.forEach((item) => {
              newList.push(item?.name)
            })

          this.Columns = newList

          break
        }

        case 'adj_day': {
          let list = this.list['Adj_container'][this.adj_stage_key]
          let newList = []

          Array.isArray(list) &&
            list.forEach((item, index) => {
              if (index == this.form.newContainerKey) {
                let j = 1
                for (let i = 0; i < item.usage_day; i++) {
                  newList.push(j)
                  j++
                }
              }
            })
          this.Columns = newList
          break
        }
      }
    },
    confirmPicker(e) {
      switch (this.type) {
        case 'ori_stage':
          this.form.ori_stage = e[0]
          if (e[0] === '三级') {
            this.containerName = '池数量'
          } else {
            this.containerName = '容器数量'
          }
          this.form.container = ''
          this.form.breed_day = ''
          this.form.containerCount = ''
          break
        case 'container':
          this.form.container = e[0]
          this.form.breed_day = ''
          this.form.containerCount = ''

          break
        case 'breed_day':
          this.form.breed_day = e[0]
          break
        case 'adj_stage':
          this.form.adj_stage = e[0]
          if (e[0] !== '三级') {
            this.form.farmName = []
          }
          this.form.newContainer = ''
          this.form.newContainerKey = ''
          break
        case 'newContainer':
          this.form.newContainer = e[0]
          this.form.newContainerKey = e[1]
          break
        case 'adj_day':
          this.form.adj_day = e[0]
          break
      }
      this.isShowPicker = false
    },
    farmChange() {
      console.log(this.farmListAll)
      this.farmListAll = this.baseList[this.form.baseName[0]] || []
      this.showPopup = false
    },

    baseChange() {
      this.farmOpts = []
      for (let key in this.baseList[this.form.baseName[0]]) {
        let obj = {
          val: key,
          key: key,
        }

        this.farmOpts.push(obj)
      }
      this.type = 'farm'
    },
    keyToValue(item) {
      switch (item) {
        case '1':
          return '保种'
        case 1:
          return '保种'
        case '2':
          return '一级'
        case 2:
          return '一级'
        case '3':
          return '二级'
        case 3:
          return '二级'
        case '4':
          return '三级'
        case 4:
          return '三级'
      }
    },

    getData() {
      this.baseList = this.list.pool_data
      this.isLoading = true
      this.$get('/algae/getEditData', {
        algae_key: this.algae_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data
          this.ori_stage_list = []
          this.adj_stage_list = []
          for (let item in res.data.dataList) {
            this.ori_stage_list.push(item)
          }
          for (let item in res.data.Adj_container) {
            this.adj_stage_list.push(item)
          }
          this.baseList = this.list.pool_data
          let last_data = res.data?.last_data

          this.record_date =
            (last_data.record_date && new Date(last_data.record_date)) ||
            this.record_date
          this.form.breed_day = last_data.cur_day || ''
          this.form.ori_stage = this.keyToValue(last_data.cur_stage) || ''
          this.form.container = last_data.cur_container || ''

          this.form.adj_stage = this.keyToValue(last_data.adj_stage) || ''
          this.form.newContainer = last_data.new_container || ''
        }
      })
    },
    getPool() {
      let list = []
      let pool = {}
      for (let key in this.pool_data) {
        if (this.p_keyGroup.includes(key)) {
          pool = {
            p_key: key,
            density: this.pool_data[key].density,
            quantity: this.pool_data[key].quantity,
          }
          list.push(pool)
        }
      }
      this.pool_group = list
    },

    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    cancelPicker() {
      this.isShowPicker = false
    },
    dateConfirm() {
      this.showDatetimePicker = false
    },
    dateCancel() {
      this.showDatetimePicker = false
    },

    getDataObj(item) {
      this.pool_data[item.p_key] = item
      return [
        [
          {
            data: item.batch,
            descText: '上次批次',
          },
          {
            data: item.last_number,
            unitText: '万',
            descText: '上次养殖',
          },
          {
            data: item.last_date && item.last_date.slice(0, 16),
            descText: '上次完结',
          },
        ],
      ]
    },

    delFarm(name) {
      this.form.farmName = this.form.farmName.filter((item) => item != name)
      // this.checkboxGroup = this.checkboxGroup.filter(
      //   (item) => item.name != name,
      // )
    },
    onSubmit() {
      let params = {
        record_date: this.dateStage,
        ori_cnt: this.form.containerCount,
        algae_key: this.algae_key, //藻类批次key
        ori_stage: this.ori_stage_key, //当前阶段
        c_key: this.c_key, //容器key
        breed_day: this.form.breed_day, //是培育天数
        adjust_cnt: this.form.adjust_cnt, //调整数量
        loss: this.form.loss, //损耗数量
        adj_stage: this.adj_stage_key, //调整阶段
        adj_container: this.Adj_container, //调整容器key
        container_cnt: this.form.container_cnt, //新容器数
        density: this.form.density + '', //密度
        adj_day: this.form.adj_day,
        quantity:
          this.form.newContainer == '池'
            ? this.poolQuantity + ''
            : this.containerQuantity + '', //数量
        //升到三级时
        pool_data: this.pool_group,
        avg_density: this.avg_density + '', //平均密度
        pool_cnt: this.p_keyGroup.length, //池数
      }
      this.btnLoading = true
      this.$post('/algae/adjust', params).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('保存成功')
          this.nextShow = true
          this.$go(-1, this)
        }
      })
    },
    wsChosedChange(item, index) {
      this.p_keyGroup = []
      this.pool_active = []
      if (this.checkboxGroup.includes(index)) {
        // 全选
        this.$nextTick(() => {
          let p_keyGroup = []
          Array.isArray(this.farmListAll[item.name]) &&
            this.farmListAll[item.name].forEach((item1, index1) => {
              p_keyGroup.push(item1.p_key)
              this.pool_active.push(index1)
            })
          console.log('全选', p_keyGroup)

          this.p_keyGroup.push(...p_keyGroup)
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.containerCount {
  margin: 0.7rem 1rem;
  font-weight: bold;
}
/deep/.hr {
  width: 100%;
  border-bottom: 0.6rem solid #eee;
}
.pool_detail {
  background-color: #eee;
  padding: 0.7rem;
  border: 1 solid #eee;
  border-radius: 0.3rem;
  margin: 0.5rem auto;
}
.delFarm {
  margin-left: 1rem;
}
</style>
