<template>
  <div class="main">
    <yiyu-navbar :tab-title="readonly ? '编辑藻类' : '新增藻类'"></yiyu-navbar>
    <yiyu-form :_this="this" :form-opts="formOpts" @submit="handleFormSubmit">
      <template #footer>
        <fixed-btn btnTxt="确定" btnNativeType="submit"></fixed-btn>
      </template>
    </yiyu-form>
    <datetime-popup
      :date.sync="form.a_date"
      :show.sync="timePickerShow"
      title="入种时间"
      @confirm="handleDatetimePopupConfirm"
      @cancel="timePickerShow = false"
      type="datetime"
    >
    </datetime-popup>
    <!-- 选择器 -->
    <!-- <picker
      :show.sync="isTagPopupShow"
      @confirm="handleTagPopupConfirm"
      @closed="tagPopupShow = null"
      :columns="formSelectionShow"
      value-key="name"
    > -->
    <picker
      :show.sync="isTagPopupShow"
      @confirm="handleTagPopupConfirm"
      :columns="formSelectionShow"
      value-key="name"
    >
      <template #empty>
        <div @click="handleAddClick(tagPopupShow)">新增</div>
      </template>
    </picker>

    <y-dialog-page-list
      :show.sync="dialogShow"
      title="新增藻种成功"
      subtitle="请选择下一步："
      :list="dialogJumpList"
      @confirm="handleDialogConfirm"
    />
  </div>
</template>
<script>
import yiyuNavbar from '@/components/navbar';
import yiyuForm from '@/components/form/yiyuForm.vue';
import datetimePopup from '@/components/popup/datetime.vue';
// import { YButtonBottom } from 'yiyu-vant-ui/lib/button'
import fixedBtn from '@/components/button/fixedBtn.vue';
import { YDialogPageList } from 'yiyu-vant-ui/lib/dialog';
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import Picker from '@/components/popup/picker.vue';

const formDataInit = () => ({
  source: undefined,
  source_value: undefined,
  breed: undefined,
  breed_value: undefined,
  a_date: undefined,
  dishes_num: undefined,
  density: undefined,
});

export default {
  name: 'algaEdit',
  components: {
    yiyuNavbar,
    yiyuForm,
    datetimePopup,
    // YButtonBottom,
    YDialogPageList,
    fixedBtn,
    Picker,
  },
  data() {
    return {
      isTagPopupShow: false,
      tagPopupShow: null,
      form: formDataInit(),
      timePickerShow: false,
      formSelection: {},
      dialogShow: false,
      dialogJumpList: [
        {
          name: '继续新增藻种',
          key: 'algaAdd',
        },
        {
          name: '藻类批次列表',
          key: 'FF_produce',
        },
        {
          name: '藻类批次详情',
          key: 'algaListDetail',
        },
        {
          name: '发送通知',
          key: 'notice',
        },
        {
          name: '返回入口',
          key: 'back',
        },
      ],
      readonly: false,
      algae_key: '', // 编辑时使用
    };
  },
  computed: {
    ...mapGetters(['USER_CONFIG_VALUE']),

    formOpts() {
      return [
        {
          child: [
            {
              label: '来源',
              required: true,
              name: 'source',
              formType: 'select',
              value: this.form.source_value,
              click: () => {
                this.isTagPopupShow = true;
                this.tagPopupShow = 'algaeSource';
              },
            },
            {
              label: '品种',
              name: 'breed',
              required: true,
              formType: 'select',
              value: this.form.breed_value,
              click: () => {
                this.isTagPopupShow = true;
                this.tagPopupShow = 'algalSpecies';
              },
            },
            {
              label: '入种时间',
              name: 'a_date',
              required: true,
              formType: 'select',
              value: this.form.a_date,
              disabled: this.readonly,
              click: () => {
                if (this.readonly) return;
                this.timePickerShow = true;
              },
            },
            {
              label: '培养皿量',
              name: 'dishes_num',
              formType: 'number',
              disabled: this.readonly,
              value: this.form.dishes_num,
              required: true,
            },
            {
              label: '入种密度',
              name: 'density',
              formType: 'number',
              disabled: this.readonly,
              value: this.form.density,
            },
            {
              label: '数量',
              name: 'number',
              value: this.count,
              readonly: true,
            },
          ],
          slot: 'append-list',
        },
      ];
    },
    formSelectionShow() {
      return this.formSelection?.[this.tagPopupShow];
    },
    count() {
      const calculate = this.form.dishes_num * this.form.density;
      return Number.isNaN(calculate) ? 0 : calculate;
    },
  },
  activated() {
    const yy_type = ['algaeSource', 'algalSpecies'];
    this.$store
      .dispatch('getUserConfList', {
        yy_type,
      })
      .then((res) => {
        yy_type.forEach((key, idx) => {
          if (!res[idx]?.data?.list) return;

          this.formSelection[key] = res[idx].data.list;
        });
        // 改变tagPopupShow的值，使得计算属性重新计算 拿到最新的值
        let tagPopupShow = this.tagPopupShow;
        this.tagPopupShow = '';
        this.tagPopupShow = tagPopupShow;
        if (this.tagPopupShow) {
          this.isTagPopupShow = true;
        }
      });
  },
  mounted() {
    const yy_type = ['algaeSource', 'algalSpecies'];
    this.$store
      .dispatch('getUserConfList', {
        yy_type,
      })
      .then((res) => {
        yy_type.forEach((key, idx) => {
          if (!res[idx]?.data?.list) return;
          this.formSelection[key] = res[idx].data.list;
        });
      });
    // 编辑页面, 获取新增数据
    if (Object.keys(this.$route.params).length > 0) {
      const { readonly, algae_key } = this.$route.params;
      this.readonly = Boolean(readonly);
      this.algae_key = algae_key;

      // 取消继续新增入口
      // this.dialogJumpList.shift()

      this.$get('/algae/editAlgae', {
        algae_key,
      })
        .then((res) => {
          const {
            density,
            dishes_num,
            record_data,
            breed,
            breed_value,
            source,
            source_value,
          } = res.data;
          this.form.breed = breed;
          this.form.breed_value = breed_value;
          this.form.source = source;
          this.form.source_value = source_value;
          this.form.density = density;
          this.form.dishes_num = dishes_num;
          this.form.a_date = record_data;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  methods: {
    handleAddClick(e) {
      this.isTagPopupShow = false;
      this.$router.push({
        name: 'confSet',
        params: {
          cached: true,
        },
        query: {
          type: e,
        },
      });
    },
    handleDialogConfirm(e) {
      switch (e) {
        case 'algaAdd':
          this.form = formDataInit();
          break;
        case 'back':
          this.$router.back();
          break;
        case 'algaListDetail':
          this.$toPage(
            this,
            'algaListDetail',
            {
              algae_key: this.algae_key,
            },
            {
              route_type: '$replace',
            }
          );
          break;
        default:
          this.$toPage(this, e);
          break;
      }
    },
    handleFormSubmit() {
      let params = {};
      for (const p of Object.keys(this.form)) {
        const a = p.split('_');
        if (this.form[p] === undefined || a[a.length - 1] === 'value') continue;
        params[p] = this.form[p];
      }

      if (this.algae_key) params.algae_key = this.algae_key;

      this.$post('/algae/addAlgae', params)
        .then((res) => {
          if (res.code === 10000) {
            this.dialogShow = true;
            this.algae_key = res.data.key;
          }
        })
        .catch((err) => {
          Toast.fail(String(err));
        });
    },
    handleTagPopupConfirm(e) {
      if (e.length > 0) {
        const _p = this.USER_CONFIG_VALUE(this.tagPopupShow, 'params');
        // store params
        this.form[_p] = e[0].key;
        this.form[`${_p}_value`] = e[0].name;
      }
    },
    handleDatetimePopupConfirm(e) {
      this.form.a_date = e;
      this.timePickerShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  min-height: 100%;
  .yiyu-form {
    /deep/ .yiyu-box-section {
      min-height: 80vh;
    }
  }
}
</style>
