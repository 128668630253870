/**
 *  池分配卡片适配器, 卡片显示所需要的数据.
 * @param {object} data
 * @param {string} data.name 批次名
 * @param {string} data.key 车间 key
 * @param {{name:string,ap_key:string,p_key:string,value:number,end_status: '1' | '2'}[]} data.pools 池数据
 * @returns {object}
 */
export const distributeCardAdapter = (data) => {
  if (!Array.isArray(data.pools)) {
    console.log('pools 必须是数组')
  }

  // 修改 池 默认数据
  data.pools.forEach((pool) => {
    pool.end_status = String(pool.end_status) || 1
  })

  return {
    name: data.name,
    algae_key: data.key,
    pool: data.pools,
  }
}
