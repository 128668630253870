<template>
  <van-popup
    position="bottom"
    v-model="showVisible"
    class="popup"
    @close="cancel"
    @closed="$emit('closed')"
    v-bind="$attrs"
  >
    <div class="header">
      <div class="row-between">
        <div class="header-left" @click="cancel">取消</div>
        <div class="header-center">{{ title }}</div>
        <div class="header-right" @click="confirm" v-if="datas && datas.length">
          确认
        </div>
        <!-- 纯属占位 -->
        <div class="header-right" v-else></div>
      </div>
      <slot name="header"></slot>
    </div>
    <slot name="top"></slot>
    <div class="content" v-myLoad="loading">
      <!-- <div v-if="canAll" class="select-all main-c" @click="choseAll">
        {{ isAll ? "取消全选" : "全选" }}
      </div> -->
      <div
        v-if="canAll"
        class="all-select"
        :class="{ active: isAll }"
        @click="choseAll"
      >
        {{ isAll ? '取消全选' : '全选' }}
      </div>
      <select-tag
        v-if="datas && datas.length"
        :opts="datas"
        :valueKey="valueKey"
        :addValueKey="addValueKey"
        v-model="keys"
        :single="single"
        :disabled-keys="disabledKeys"
        :keyName="keyName"
        :curObjs.sync="objs"
        :classify="classify"
        @change="change"
      ></select-tag>
      <van-divider v-else>暂无可选择项</van-divider>
      <div class="empty">
        <slot name="empty">
          <!-- <van-divider>暂无数据</van-divider> -->
        </slot>
      </div>
    </div>
    <slot name="bottom"></slot>
  </van-popup>
</template>

<script>
import selectTag from '@/components/tag/selectTag.vue'
import { objToArr } from '@/utils/utils'
export default {
  name: 'tagPopup',
  inheritAttrs: false,
  // 触发事件
  // confirm , 返回 选中 的 keys (arr)
  // cancel
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      // 需要加 sync 修饰符
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    opts: {
      type: [Array, Object],
      default: () => [],
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    curKeys: {
      type: Array,
      default: () => [],
    },
    single: {
      // 单选-多选
      type: Boolean,
      default: false,
    },
    disabledKeys: {
      // 禁止选中的key
      type: Array,
      default: () => [],
    },
    keyName: {
      type: String,
      default: 'key',
    },
    addValueKey: {
      // 用来将两个value进行拼接
      type: String,
    },
    classify: Object, // {key,label} 根据哪个key进行分类，用label展示
    canAll: {
      // 是否支持全选
      type: Boolean,
      default: false,
    },
  },
  components: {
    selectTag,
  },
  data() {
    return {
      showVisible: this.show,
      keys: JSON.parse(JSON.stringify(this.curKeys)),
      objs: [],
      datas: [],
    }
  },
  computed: {
    isAll() {
      return this.keys.length + this.disabledKeys.length == this.datas.length
    },
  },
  created() {
    this.keys = JSON.parse(JSON.stringify(this.curKeys))
    this.dataDeal()
  },
  watch: {
    showVisible(val) {
      this.$emit('update:show', val)
    },
    show(val) {
      this.showVisible = val
    },

    curKeys: {
      deep: true,
      handler() {
        this.keys = JSON.parse(JSON.stringify(this.curKeys))
      },
    },
    opts: {
      deep: true,
      handler() {
        this.dataDeal()
      },
    },
  },
  methods: {
    dataDeal() {
      if (Array.isArray(this.opts)) {
        this.datas = JSON.parse(JSON.stringify(this.opts))
      } else {
        this.datas = objToArr(this.opts, this.keyName, this.valueKey)
      }
    },
    confirm() {
      this.$emit('confirm', this.keys)
      this.$emit('confirmObj', this.objs)
    },
    change() {
      this.$emit('change', this.keys)
    },
    cancel() {
      this.$emit('cancel')
    },
    choseAll() {
      if (this.isAll) {
        this.keys = []
      } else {
        let keys = []
        this.datas.forEach((item) => {
          if (this.disabledKeys.includes(item[this.keyName]) == false) {
            keys.push(item[this.keyName])
          }
        })
        this.keys = keys
      }
    },
  },
}
</script>

<style lang="less" scoped>
.popup {
  display: flex;
  flex-direction: column;
  .header {
    font-size: 1.1rem;
    padding: 1rem;
    padding-bottom: 0;
    text-align: center;

    .row-between {
      display: flex;
      justify-content: space-between;
    }

    &-left {
      color: @textColor;
    }

    &-right {
      color: @mainColor;
    }

    &-center {
      color: @titleColor;
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .content {
    max-height: 50vh;
    overflow-y: auto;
    flex-basis: 100%;
    position: relative;
    padding: 1rem;

    .select-all {
      padding-left: 1rem;
    }
  }

  .all-select {
    border-radius: 8px;
    margin: 8px;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    color: @titleColor;
    font-weight: 600;
    background: @bgColor;
    text-align: center;

    &.active {
      color: @mainColor;
      background: rgba(@mainRgb, 0.08);
    }
  }
}
</style>
