<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      :form="form"
      @submit="onSubmit"
      ref="form"
    >
      <template #containerCount>
        <div class="containerCount" v-if="form.breed_day">
          {{ containerName }}：{{ form.containerCount }}个
        </div>
      </template>
    </yiyu-form>
    <van-popup v-model="showDatetimePicker" round position="bottom">
      <van-datetime-picker
        type="date"
        v-model="record_date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
    <yiyu-picker
      v-if="isShowPicker"
      :show.sync="isShowPicker"
      :columns="Columns"
      value-key="name"
      @confirm="confirmPicker($event)"
      @cancel="cancelPicker()"
    >
    </yiyu-picker>
    <van-popup
      class="popup"
      v-model="showPopup"
      round
      position="bottom"
      closeable
    >
      <select-tag
        :opts="poolOptsList"
        :single="false"
        v-model="form.poolLoss"
      ></select-tag>
      <fixed-btn btnTxt="确认" @click="poolClick"></fixed-btn>
    </van-popup>
    <fixed-btn
      btnTxt="保存"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
import { formatTime } from '@/utils/date'
import selectTag from '@/components/tag/selectTag'

export default {
  name: 'out',
  components: {
    yiyuForm,
    yiyuPicker,
    fixedBtn,
    yiyuNavbar,
    selectTag,
  },
  mounted() {
    this.thisAlias = this
    if (this.$route.query) {
      let query = this.$route.query
      this.algae_key = query.algae_key
      this.getData()
    }
  },
  watch: {
    'form.breed_day': {
      deep: true,
      handler() {
        if (this.form.ori_stage === '三级') {
          this.form.containerCount =
            this.list?.dataList[this.ori_stage_key][this.form.breed_day]
              ?.count || '1'
        } else {
          let obj =
            this.list.dataList[this.ori_stage_key || 1][this.form.container]
              ?.detail[this.form.breed_day] || 1
          console.log(obj)
          this.form.containerCount = obj
        }
      },
    },
  },
  computed: {
    tabTitle() {
      return this.list.batch || '淘汰记录'
    },

    // eslint-disable-next-line vue/return-in-computed-property
    poolOptsList() {
      if (this.ori_stage_key == '4') {
        let newlist = []
        let obj
        let list = this.list.dataList['4'][this.form.breed_day]?.pool
        list?.forEach((item) => {
          obj = {
            val: item.name,
            key: item.key,
          }
          newlist.push(obj)
        })
        console.log(newlist)
        return newlist
      }
    },
    dateStage() {
      let date = formatTime(this.record_date).slice(0, 10)
      return date
    },
    minDate() {
      return new Date(parseInt(new Date().getFullYear()) - 5, 0, 1)
    },
    maxDate() {
      return new Date(parseInt(new Date().getFullYear()) + 5, 10, 1)
    },
    c_key() {
      if (this.ori_stage_key == '4' && this.form.ori_container == '池') {
        return ''
      }
      return this.list?.dataList[this.ori_stage_key][this.form.container]?.key
    },

    formOpts() {
      let _this = this
      let opts = [
        {
          child: [
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.dateStage,
              name: 'dateStage',
              label: '操作日期',
              placeholder: '',
              click() {
                _this.showDatetimePicker = true
              },
              rules: [{ required: true, message: '请选择默认日期' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.form.ori_stage,
              name: 'ori_stage',
              label: '藻类阶段',
              click() {
                _this.type = 'ori_stage'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择当前阶段' }],
            },
            {
              show: this.form.ori_stage,
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.container,
              name: 'container',
              label: '当前容器',
              click() {
                _this.type = 'container'
                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请选择容器计量单位' }],
            },
            {
              show: this.form.container,
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.breed_day,
              name: 'breed_day',
              label: '培育天数',
              extra: '天',
              click() {
                _this.type = 'breed_day'

                _this.ColumnsList()
                _this.isShowPicker = true
              },
              rules: [{ required: true, message: '请输入培育天数' }],
              bottomSlot: 'containerCount',
            },
            {
              show: this.form.container !== '池',
              required: false,
              colon: true,
              formType: 'input', // 默认
              value: this.form.loss,
              name: 'loss',
              label: '淘汰容器',
              rules: [{ required: true, message: '请填写淘汰容器' }],
            },
            {
              show: this.form.container == '池',
              required: true,
              colon: true,
              formType: 'select', // 默认
              value: this.form.poolLoss.length,
              name: 'loss_pool',
              label: '淘汰池数',
              click() {
                _this.showPopup = true
              },
              rules: [{ required: true, message: '请选择当前阶段' }],
            },
          ],
        },
      ]
      return opts
    },
    // eslint-disable-next-line vue/return-in-computed-property
    ori_stage_key() {
      switch (this.form.ori_stage) {
        case '保种':
          return '1'
        case '一级':
          return '2'
        case '二级':
          return '3'
        case '三级':
          return '4'
      }
    },
  },
  data() {
    return {
      Columns: [],
      list: {
        datalist: {},
        Adj_container: {},
        last_data: {},
        pool_data: {},
      },
      poolOpts: [],
      ori_stage_list: [],
      isShowPicker: false,
      showPopup: false,
      record_date: new Date(),
      containerName: '容器数量',
      thisAlias: this,
      btnLoading: false,
      showDatetimePicker: false,
      algae_key: '', //藻类批次key
      form: {
        ori_stage: '', //当前阶段
        breed_day: '1', //是培育天数
        containerCount: 2,
        loss: '',
        container: '',
        poolLoss: [],
      },
    }
  },
  methods: {
    ColumnsList() {
      switch (this.type) {
        case 'ori_stage':
          this.Columns = []

          this.ori_stage_list.forEach((item) => {
            this.Columns.push(this.keyToValue(item))
          })
          break
        case 'container': {
          // console.log(Object.keys(this.list[this.ori_stage_key]))
          // Object.keys(this.list[this.ori_stage_key])
          let list = this.list.dataList[this.ori_stage_key]
          this.Columns = Object.keys(list)
          if (
            this.list?.cur_pool.length != 0 &&
            this.form.ori_stage == '三级'
          ) {
            this.Columns.push('池')
          }
          // this.Columns = Object.keys(this.list.dataList[this.ori_stage_key])
          break
        }

        case 'breed_day':
          if (this.form.ori_stage == '三级' && this.form.container == '池') {
            this.Columns = Object.keys(this.list.cur_pool)
          } else {
            let obj =
              this.list.dataList[this.ori_stage_key][this.form.container]
                ?.detail
            this.Columns = Object.keys(obj)
          }
          break
      }
    },
    dateConfirm() {
      this.showDatetimePicker = false
    },

    dateCancel() {
      this.showDatetimePicker = false
    },
    close() {
      this.showPopup = false
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    confirmPicker(e) {
      switch (this.type) {
        case 'ori_stage':
          this.form.ori_stage = e[0]
          if (e[0] === '三级') {
            this.containerName = '池数量'
          } else {
            this.containerName = '容器数量'
          }
          this.form.container = ''
          this.form.breed_day = ''
          this.form.containerCount = ''
          break
        case 'container':
          this.form.container = e[0]
          this.form.breed_day = ''
          this.form.containerCount = ''

          break
        case 'breed_day':
          this.form.breed_day = e[0]
          if (this.form.ori_stage === '三级') {
            this.form.containerCount =
              this.list.dataList[this.ori_stage_key][e[0]]?.count
          } else {
            this.form.containerCount =
              this.list.dataList[this.ori_stage_key][
                this.form.container
              ]?.detail[e[0]]
          }
          break
      }
      this.isShowPicker = false
    },
    cancelPicker() {
      this.isShowPicker = false
    },
    poolClick() {
      this.showPopup = false
    },
    keyToValue(item) {
      switch (item) {
        case '1':
          return '保种'
        case 1:
          return '保种'
        case '2':
          return '一级'
        case 2:
          return '一级'
        case '3':
          return '二级'
        case 3:
          return '二级'
        case '4':
          return '三级'
        case 4:
          return '三级'
      }
    },
    getData() {
      this.baseList = this.list.pool_data
      this.isLoading = true
      this.$get('/algae/getEditData', { algae_key: this.algae_key }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false
            for (let item in res.data.dataList) {
              this.ori_stage_list.push(item)
            }
            this.list = res.data
            let last_data = res.data?.last_data
            console.log(last_data)

            // this.record_date =
            //   (last_data.record_date && new Date(last_data.record_date)) ||
            //   this.record_date
            this.form.breed_day = last_data.cur_day || ''
            this.form.ori_stage = this.keyToValue(last_data.cur_stage) || ''
            this.form.container = last_data.cur_container || ''

            console.log(this.list)
          }
        },
      )
    },
    onSubmit() {
      let params = {
        record_date: this.dateStage, //记录日期  必传
        algae_key: this.algae_key, //藻类批次key 必传
        stage: this.ori_stage_key, //阶段 必传
        c_key: this.c_key, //容器key 若容器不为池就一定要传 否则也不用传
        breed_day: this.form.breed_day, //是培育天数 必传
        //若不是淘汰池则要有loss 若是淘汰池就一定要loss_pool
        loss: this.form.loss,
        cur_number: this.form.containerCount,
        loss_pool: this.form.poolLoss,
        //要淘汰的池的p_key
      }
      console.log(params)
      this.btnLoading = true
      this.$post('/algae/loss', params).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('淘汰成功')
          this.nextShow = true
          console.log(res)
          this.$go(-1, this)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.containerCount {
  margin: 0.7rem 1rem;
  font-weight: bold;
}
</style>
