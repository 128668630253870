<template>
  <div class="main">
    <yiyu-bar tab-title="基地详情"></yiyu-bar>
    <div class="main-content">
      <div class="row-between">
        <div class="bold-title">{{ list.name }}-藻类车间</div>

        <handle-popver
          @del="del"
          @edit="edit"
          :actions="actions"
        ></handle-popver>
      </div>

      <yiyu-detail :formOpts="formOpts" class="relative">
        <template #pool>
          <data-field :dataObj="dataObj"> </data-field>
          <el-table
            :header-cell-style="{ background: '#fafafa' }"
            :cell-class-name="cellClassName"
            :data="breedPropsData"
            size="small"
            @row-click="tableClick($event, 'algaListDetail')"
          >
            <el-table-column
              align="center"
              v-for="(value, index) in breedListData"
              :key="index"
              :prop="value.prop"
              :label="value.label"
            >
              <template slot-scope="scope">
                <template v-if="value.prop === 'name'">
                  <span class="mainColor">{{ scope.row[value.prop] }}</span>
                </template>
                <template v-else>{{
                  scope.row[value.prop] && scope.row[value.prop] + '池'
                }}</template>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            :header-cell-style="{ background: '#fafafa' }"
            :data="breedEndPropsData"
            size="small"
            v-if="breedEndPropsData.length"
            @row-click="tableClick($event, 'algaListDetail')"
          >
            <el-table-column
              align="center"
              v-for="(value, index) in breedEndListData"
              :key="index"
              :prop="value.prop"
              :label="value.label"
            >
              <template slot-scope="scope">
                <template v-if="value.prop === 'p_count'">
                  <span>{{ scope.row[value.prop] + '池' }}</span>
                </template>
                <template v-else-if="value.prop === 'batch'">
                  <span class="main-c">{{ scope.row[value.prop] }}</span>
                </template>
                <template v-else>{{ scope.row[value.prop] }}</template>
              </template>
            </el-table-column>
          </el-table>

          <see-more
            class="more"
            @change="showMore"
            v-if="breedEndPropsData.length > 3"
          ></see-more>
        </template>
      </yiyu-detail>
      <keep-alive>
        <ws-pool-data :mc_key="mc_key" ws_type="3" v-if="mc_key"></ws-pool-data>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { table } from '@/utils/mixins'
import { Dialog } from 'vant'
import wsPoolData from './components/wsPoolData'
import handlePopver from '@/components/feedback/handlePopver'
import seeMore from '@/components/seeMore/seeMore'
import yiyuBar from '@/components/navbar'
import yiyuDetail from '@/components/detail'
import dataField from '@/components/layout/dataField'

export default {
  name: 'algaeDetail',
  mixins: [table],
  components: {
    yiyuDetail,
    dataField,
    yiyuBar,
    seeMore,
    handlePopver,
    wsPoolData,
  },
  data() {
    return {
      mc_key: undefined,
      list: {},
      actions: [
        {
          text: '编辑',
          fn: 'edit',
        },
        {
          text: '删除',
          fn: 'del',
        },
      ],
      breedPropsData: [],
      breedEndPropsData: [],
      breedEndAllPropsData: [],
      breedEndListData: [
        {
          label: '已完结',
          prop: 'batch',
        },
        {
          label: '池数',
          prop: 'p_count',
        },
        {
          label: '完结日期',
          prop: 'finish',
        },
      ],
      breedListData: [
        {
          label: '培育中',
          prop: 'batch',
        },
        {
          label: '第一天',
          prop: 'p_count_day1',
        },
        {
          label: '第二天',
          prop: 'p_count_day2',
        },
        {
          label: '第三天',
          prop: 'p_count_day3',
        },
      ],
      more: true,
      ws_type: '',
      postLoading: false,
    }
  },
  computed: {
    formOpts() {
      return [
        {
          title: '池情况',
          slot: 'pool',
        },
      ]
    },
    dataObj() {
      return [
        [
          {
            data: this.list.pool_count,
            unitText: '池',
            descText: '总池数',
          },
          {
            data: this.list.pool_empty,
            unitText: '池',
            descText: '空置中',
          },
          {
            data: this.list.pool_breed,
            unitText: '池',
            descText: '养殖中',
          },
        ],
      ]
    },
  },

  mounted() {
    if (this.$route.query) {
      this.mc_key = this.$route.query.mc_key

      this.getData()
    }
  },
  activated() {},
  methods: {
    tableClick(e, name) {
      this.$toPage(this, name, { algae_key: e.algae_key })
    },
    toPage(type) {
      switch (type) {
        case 'sPoolList':
          this.$toPage(this, type, {
            mc_key: this.mc_key,
            ws_type: 2,
          })
          break
      }
    },
    showMore() {
      if (this.more) {
        this.breedEndPropsData = this.breedEndAllPropsData
        this.$nextTick(() => {
          this.more = !this.more
        })
      } else {
        this.breedEndPropsData &&
          (this.breedEndPropsData = this.breedEndPropsData.slice(0, 3))
        this.more = !this.more
      }
    },
    getData() {
      this.$get('/mc/getMCList', {
        mc_key: this.mc_key,
        ws_type: 3,
      }).then((res) => {
        if (res.code == 10000) {
          this.list = res.data.list?.[0]
          this.breedPropsData = res.data.list[0].als.breed
          this.breedEndAllPropsData = res.data.list[0].als.end
          this.breedEndPropsData = this.breedEndAllPropsData.slice(0, 3)
        }
      })
    },
    edit() {
      this.$toPage(this, 'farmEdit', {
        mc_key: this.mc_key,
        status: 'edit',
        ws_type: 2,
      })
    },
    del() {
      Dialog.confirm({
        title: '是否确认删除',
        message: '删除基地后，对应的批次也将同时删除',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/mc/delMC', {
            mc_key: this.mc_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除基地成功')
              this.$go(-1, this, {
                name: 'farmMgt',
              })
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style lang="less" scoped>
.bold-title {
  display: flex;
  color: @titleColor;
  font-weight: 700;
  font-size: 1.2rem;
  align-items: center;
}
.more {
  margin-top: 0.8rem;
  text-align: center;
  color: rgb(0, 68, 255);
}
.mainColor {
  color: @mainColor;
}
.row-between {
  padding: 1rem;
}
.relative {
  padding: 0.5rem;
}
</style>
