<template>
  <div class="yiyu-see-more">
    <collapse-transition>
      <div class="collapse-wrap" v-show="show">
        <slot></slot>
      </div>
    </collapse-transition>
    <div class="main-c txt-c more-icon" @click="show = !show">
      <span>{{ show ? '收起所有' : '展开所有' }}</span>
      <img
        src="@/assets/icons/down.png"
        class="w20 h20 img-filter"
        alt=""
        v-if="!show"
      />
      <img
        src="@/assets/icons/up.png"
        class="w20 h20 img-filter"
        alt=""
        v-if="show"
      />
    </div>
  </div>
</template>

<script>
import collapseTransition from './collapse.js'
export default {
  name: 'seeMore',
  data() {
    return {
      show: false,
    }
  },
  components: {
    collapseTransition,
  },
  watch: {
    show() {
      this.$emit('change', this.show)
    },
  },
}
</script>
<style lang="less" scoped>
.more-icon {
  .FlexCenter;
  font-size: 1rem;
  font-weight: 500;
}
</style>
