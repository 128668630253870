<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <div class="add main-c" @click="toPage('add')">
      <van-icon name="plus"></van-icon>容器
    </div>

    <div class="yiyu-list" v-if="!list.length == 0">
      <div
        class="list-item"
        v-for="item in list"
        :key="item.c_key"
        @click="toPage('edit', item.c_key)"
      >
        <div class="list-item-top">
          <div class="left">
            <span class="name">{{ item.c_name || '暂无' }}</span>
          </div>
          <div class="right"><van-icon name="arrow" /></div>
        </div>
        <div>
          <span class="list-item-label">使用阶段</span>
          <span>{{ item.c_stage }}</span>
        </div>
        <div>
          <span class="list-item-label">容量</span>
          <span>{{ item.capacity }}{{ item.c_unit }}</span>
        </div>
        <div v-if="item.c_stage != '保种'">
          <span class="list-item-label">培育天数</span>
          <span>{{ item.usage_day }}</span>
        </div>
      </div>
    </div>
    <yiyu-empty v-else> </yiyu-empty>
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar'
import yiyuEmpty from '@/components/layout/empty.vue'

export default {
  name: 'containerList',

  components: {
    yiyuNavbar,
    yiyuEmpty,
  },
  data() {
    return {
      tabTitle: '容器管理',
      list: [],
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    toPage(type, key) {
      switch (type) {
        case 'add':
          this.$toPage(this, 'containerEdit')
          break
        case 'edit':
          console.log(key)
          this.$toPage(this, 'containerEdit', { c_key: key })
      }
    },
    getData() {
      let params = {
        page: 1, // 页数
        count: 10,
      }
      this.isLoading = true
      this.$get('/container/getContainerList', params).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data.list
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.add {
  text-align: right;
  margin: 0.4rem;
}
</style>
