<template>
  <div class="h-full">
    <yy-navbar tabTitle="选择批次">
      <!-- 覆盖返回主页按钮 -->
      <template #right>
        <div></div>
      </template>
    </yy-navbar>
    <y-filter
      :data="filterOptions"
      @search="mixinFilterSearch"
      @reset="filterReset"
      ref="filter"
    />
    <div class="m-4">
      <div class="my-4" v-for="(batch, batchKey, idx) in list" :key="idx">
        <div class="flex justify-between">
          <div class="flex items-center">
            <input
              :checked="isBatchChecked(batchKey)"
              type="checkbox"
              class="h-4 w-4 appearance-none rounded-full border"
              @change="handleBatchCheckbox($event, batchKey)"
            />
            <!-- <van-checkbox v-model="isWorkspaceChecked" /> -->
            <span class="ml-1 truncate w-48" @click="handleShowPool(idx)">{{
              batch.batch
            }}</span>
            <span class="ml-2">({{ batch.count }})</span>
          </div>
          <span class="main-c">已选中{{ checkedCount(batchKey) }}池</span>
        </div>
        <collapseTransition>
          <div class="ml-4" v-show="showPool === idx">
            <check-button-group
              :list="checkBoxList(batch)"
              :isCheck="isPoolChecked(batchKey)"
              :isCheckbox="true"
              @check="handleCheckbox($event, batchKey)"
              :ref="`checkGroup-${batchKey}`"
            />
            <div v-if="batch.finish_data" class="mx-2">
              <span>已完结</span>
              <div class="flex flex-wrap">
                <div
                  class="px-3 m-1 bg-gray-400 text-white rounded select-none"
                  v-for="pool in batch.finish_data"
                  :key="pool.p_key"
                >
                  {{ pool.p_name }}
                </div>
              </div>
            </div>
          </div>
        </collapseTransition>
      </div>
    </div>
    <fixed-btn btnTxt="确认" @click="jumpBack" />
  </div>
</template>

<script>
import algaMixin from './algaMixin'
import { YFilter } from 'yiyu-vant-ui/lib/filter'
import checkButtonGroup from '@/components/checkButtonGroup.vue'
import FixedBtn from '@/components/button/fixedBtn.vue'
import bus from '@/utils/bus'
import collapseTransition from '@/components/seeMore/collapse'

export default {
  components: {
    YFilter,
    checkButtonGroup,
    FixedBtn,
    collapseTransition,
  },
  mixins: [algaMixin],
  data() {
    return {
      tabCur: 'rank-three',
      list: {},
      workspaceChecked: {},
      isWorkspaceChecked: true,
      showPool: 0,
    }
  },
  computed: {
    checkBoxList() {
      return (batch) => {
        const { pool_data: list } = batch
        if (!Array.isArray(list)) return []
        return list.map((item) => ({
          name: item.p_name,
          key: item.p_key,
        }))
      }
    },
    isPoolChecked() {
      return (target) =>
        Array.isArray(this.workspaceChecked[target])
          ? this.workspaceChecked[target]
          : []
    },
    checkedCount() {
      return (target) =>
        Array.isArray(this.workspaceChecked[target])
          ? this.workspaceChecked[target].length
          : 0
    },
    isBatchChecked() {
      return (target) =>
        this.workspaceChecked?.[target]?.length > 0 ? true : false
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    initData() {
      this.list = {}
    },
    getData(page, count, params) {
      this.$get('/distribute/getBatch', {
        // stage: this.algaPage[this.tabCur].stage,
        stage: 4,
        ...params,
      }).then((res) => {
        const { dataList } = res.data
        this.list = dataList
      })
    },
    handleCheckbox(e, target) {
      if (e.length === 0) {
        this.$delete(this.workspaceChecked, target)
        return
      }
      Array.isArray(this.workspaceChecked[target])
        ? (this.workspaceChecked[target] = e)
        : this.$set(this.workspaceChecked, target, e)
    },
    handleBatchCheckbox(e, target) {
      if (
        !this.workspaceChecked?.[target] ||
        this.workspaceChecked?.[target].length === 0
      ) {
        this.$refs[`checkGroup-${target}`][0].change(undefined, 'all')
      } else {
        this.$refs[`checkGroup-${target}`][0].currentIndexGroup = []
        this.$delete(this.workspaceChecked, target)
      }
    },
    handleShowPool(idx) {
      this.showPool = idx
    },
    jumpBack() {
      const _r = Object.keys(this.workspaceChecked).map((worksapceKey) => {
        let poolKeyMapName = {}
        let poolApKey = {}
        if (Array.isArray(this.list[worksapceKey]?.pool_data)) {
          poolKeyMapName = this.list[worksapceKey].pool_data.reduce(
            (obj, acc) => ({
              ...obj,
              [acc.p_key]: acc.p_name,
            }),
            {},
          )

          poolApKey = this.list[worksapceKey].pool_data.reduce(
            (obj, acc) => ({
              ...obj,
              [acc.p_key]: acc.ap_key,
            }),
            {},
          )
        }

        return {
          name: this.list[worksapceKey].batch,
          algae_key: worksapceKey,
          totalDistribute: 0,
          pool: this.workspaceChecked[worksapceKey].map((poolKey) => ({
            name: poolKeyMapName[poolKey],
            ap_key: poolApKey[poolKey],
            // ap_key: poolKey,
            p_key: poolKey,
            value: 0,
            end_status: '1',
          })),
        }
      })
      bus.$emit('update-batch-list', _r)
      this.$router.back()
    },
  },
}
</script>

<style>
input:checked {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e"); */
  background-color: var(--mainColor);
  justify-content: center;
}
</style>
