import { get, isObject, isString, mapValues } from 'lodash'

export class WordMap {
  constructor(wordMap) {
    this.wordMap = wordMap
  }

  getPureObj() {
    const unWrapValue = (o) => {
      return isObject(o) ? o.value : o
    }

    const parseWordMapObj = (o) => {
      return isString(unWrapValue(o)) ? unWrapValue(o) : mapWordMapObj(o)
    }

    const mapWordMapObj = (o) => mapValues(o, parseWordMapObj)

    return mapWordMapObj(this.wordMap)
  }

  getSuffix(path) {
    return get(this.wordMap, path)?.suffix ?? ''
  }

  getValue(path) {
    const val = get(this.wordMap, path)
    return val?.value ?? val
  }
}
