<template>
  <!-- FIXME:车间与池用这个组件 -->
  <section class="ws-pool-data relative" v-myLoad="poolLoading">
    <div class="row-between">
      <div class="bold-title">车间与池</div>
      <div class="handles main-c" @click="addWsShow = true">+车间</div>
    </div>
    <van-divider v-if="!poolWsList || !poolWsList.length">暂无数据</van-divider>
    <select-tag
      :opts="poolWsList"
      valueKey="ws_name"
      keyName="ws_key"
      :single="true"
      v-model="wsKey"
    ></select-tag>
    <van-divider v-if="poolWsList && poolWsList.length && !wsKey.length"
      >请选择一个车间</van-divider
    >
    <div class="pool-data">
      <div
        class="pool-data-item"
        v-for="(item, index) in poolWsList"
        :key="index + 'ws'"
      >
        <template v-if="item.ws_key == wsKey">
          <div class="row-between">
            <div class="sub-title">
              {{ item.ws_name }}
            </div>
            <handle-popver
              @editWs="editWs(item)"
              @delWs="delWs(item)"
              @addPool="addPool(item)"
              @delPool="delPool(item)"
              :actions="actions2"
            ></handle-popver>
          </div>
          <div class="single-ele-table pool-table">
            <el-table
              v-if="item.children && item.children.length"
              :header-cell-class-name="headerCellClassName"
              :cell-class-name="cellClassName"
              :row-class-name="rowClassName"
              :cell-style="{ color: '#666666' }"
              :data="item.children"
              size="small"
              max-height="370"
              @row-click="rowClick($event)"
              header-row-class-name="tableHead"
            >
              <el-table-column
                v-for="(value, index) in poolHeader"
                :key="index"
                :prop="value.prop"
                :label="value.label"
                :min-width="columnWidth(value.prop)"
                align="center"
                :fixed="value.prop == 'ws_name' || value.prop == 'p_name'"
              >
                <template slot-scope="scope">
                  <template v-if="value.prop == 'p_name'">
                    <span class="main-c">{{ scope.row[value.prop] }}</span>
                  </template>
                  <template v-else-if="value.prop == 'status'">
                    <span>{{
                      scope.row[value.prop] == 1 ? '养殖中' : '空置'
                    }}</span>
                  </template>
                  <template v-else-if="value.prop == 'batch'">
                    <span class="main-c" @click.stop="cellClick(scope.row)">{{
                      scope.row[value.prop]
                    }}</span>
                  </template>
                  <template v-else>{{ scope.row[value.prop] }}</template>
                </template>
              </el-table-column>
            </el-table>
            <van-divider v-else>暂无池数据</van-divider>
          </div>
        </template>
      </div>
    </div>

    <van-dialog
      v-model="addPoolShow"
      title="新增池名称"
      show-cancel-button
      @confirm="addPoolPost"
      @cancel="addPoolShow = false"
      :before-close="beforeClose"
    >
      <van-field
        v-model="curItem.p_name"
        placeholder="请输入池名称"
      ></van-field>
    </van-dialog>

    <van-dialog
      v-model="editWsShow"
      title="编辑车间"
      show-cancel-button
      @cancel="editWsShow = false"
      @confirm="editWorkshop"
      cancelButtonText="取消"
      :before-close="notClose"
    >
      <van-field
        v-model="curItem.ws_name"
        placeholder="请输入车间名称"
      ></van-field>
    </van-dialog>

    <add-ws
      :showVisible.sync="addWsShow"
      :data.sync="newWsData"
      @confirm="addWorkshop"
    ></add-ws>

    <tag-popup
      :show.sync="delPoolShow"
      v-if="delPoolShow"
      title="删除池"
      :curKeys="delPoolKeys"
      keyName="p_key"
      :opts="delPoolopts"
      valueKey="p_name"
      :canAll="true"
      @confirm="delPoolPost"
      @cancel="delPoolShow = false"
    >
      <template #empty>
        <div class="del-pool-tip">
          <van-icon
            name="warning-o"
          />删除池后，关联的该池的批次信息将会同时删除。
        </div>
      </template>
    </tag-popup>
  </section>
</template>

<script>
import { table } from '@/utils/mixins'
import { Dialog } from 'vant'
import selectTag from '@/components/tag/selectTag'
import { classifyData } from '@/utils/utils'
import tagPopup from '@/components/popup/tag'
import addWs from './addWs'
import handlePopver from '@/components/feedback/handlePopver'
export default {
  name: 'wsPoolData',
  mixins: [table],
  props: {
    mc_key: {
      required: true,
      type: String,
    },
    ws_type: {
      type: String,
    },
    ss_key: {
      type: String,
    },
  },
  components: {
    selectTag,
    tagPopup,
    addWs,
    handlePopver,
  },
  data() {
    return {
      wsKey: [],
      delPoolShow: false,
      delPoolKeys: [],
      delPoolopts: [],
      poolWsList: [], // 区分车间
      poolLoading: false,
      actions2: [
        // 车间池操作-更多
        { text: '编辑车间', fn: 'editWs' },
        { text: '删除车间', fn: 'delWs' },
        { text: '新增池', fn: 'addPool' },
        { text: '删除池', fn: 'delPool' },
      ],
      curItem: {}, // 当前操作的数据
      addPoolShow: false,
      addWsShow: false,
      editWsShow: false,
      newWsData: {}, // 新增车间数据
    }
  },
  activated() {
    console.log('ws-pool-data-activated')
    this.getPoolData()
  },
  watch: {
    mc_key() {
      this.getPoolData()
    },
    ss_key() {
      this.getPoolData()
    },
  },
  computed: {
    poolHeader() {
      if (this.ws_type == 3) {
        return [
          {
            label: '池号',
            prop: 'p_name',
          },
          {
            label: '状态',
            prop: 'status',
          },
          {
            label: '批次',
            prop: 'batch',
          },
          {
            label: '分配量',
            prop: 'assigned_amount',
          },
        ]
      } else {
        return [
          {
            label: '池号',
            prop: 'p_name',
          },
          {
            label: '状态',
            prop: 'status',
          },
          {
            label: '批次',
            prop: 'batch',
          },
          {
            label: '种虾性别',
            prop: 'sex_val',
          },
          {
            label: '种虾数量',
            prop: 'count',
          },
        ]
      }
    },
  },
  methods: {
    rowClick(e) {
      this.toPage('poolDetail', e)
    },
    cellClick(row) {
      this.toPage('sBatchDetail', row)
      console.log(row)
    },
    // 获取池数据
    getPoolData() {
      this.poolLoading = true
      let url = '/mc/getLPool'
      if (this.ss_key) {
        url = '/settle/getSSPList'
      }
      this.$get(url, {
        mc_key: this.mc_key,
        ws_type: this.ws_type,
        ss_key: this.ss_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.poolLoading = false

          this.poolData = res.data
          let list = res.data.list || []
          list &&
            list.forEach((item) => {
              item.end_date = item.end_date && item.end_date.slice(2, 10)
              item.launch_date =
                item.launch_date && item.launch_date.slice(2, 10)
            })

          this.poolWsList = classifyData(list, 'ws_key', 'ws_name')

          // 过滤掉 有车间没池 的 children
          this.poolWsList.forEach((item) => {
            if (item.children && item.children[0] && !item.children[0].p_key) {
              item.children = []
            }
          })

          if (this.wsKey && this.wsKey.length) {
            // 不变
          } else {
            if (this.poolWsList && this.poolWsList.length) {
              this.wsKey = [this.poolWsList[0].ws_key]
            }
          }
        }
      })
    },
    // 编辑车间
    editWs(item) {
      this.editWsShow = true
      this.curItem = JSON.parse(JSON.stringify(item))
    },
    // 删除车间
    delWs(item) {
      Dialog.confirm({
        title: '删除提醒',
        message:
          '删除车间后，池的所有关联信息都将删除，删除后数据无法找回，是否确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/ws/delWorkshop', {
            ws_key: (item && item.ws_key) || this.curItem.ws_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除车间成功')
              this.getPoolData()
              this.editWsShow = false
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    addPool(item) {
      this.curItem = JSON.parse(JSON.stringify(item))
      this.addPoolShow = true
    },
    // 新增池
    addPoolPost() {
      if (!this.curItem.p_name) {
        this.$toast.fail('池名称不能为空哦')
        return
      }
      this.$post('/pool/addPool', {
        ws_key: this.curItem.ws_key,
        name: this.curItem.p_name,
      }).then((res) => {
        if (res.code == 10000) {
          this.addPoolShow = false
          this.$toast.success('新增池成功!')
          this.getPoolData()
        }
      })
    },
    // 删除池
    delPool(item) {
      this.delPoolopts = item.children
      this.delPoolShow = true
    },
    // 删除池
    delPoolPost(e) {
      this.delPoolKeys = e
      Dialog.confirm({
        title: '删除提醒',
        message:
          '删除池后所有关联信息都将删除，删除后数据无法找回，是否确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/pool/delPool', { p_key: this.delPoolKeys }).then(
            (res) => {
              this.postLoading = false
              if (res.code == 10000) {
                this.$toast.success('删除池成功')
                this.getPoolData()
                this.delPoolShow = false
              }
            },
          )
        })
        .catch(() => {
          // on cancel
        })
    },
    // 修改车间
    editWorkshop() {
      this.$post('/ws/addWorkshop', {
        ws_key: this.curItem.ws_key,
        mc_key: this.mc_key,
        name: this.curItem.ws_name,
      }).then((res) => {
        if (res.code == 10000) {
          this.editWsShow = false
          this.$toast.success('修改车间成功!')
          this.getPoolData()
        }
      })
    },
    // 添加车间
    addWorkshop() {
      this.$post('/ws/addWorkshop', {
        mc_key: this.mc_key,
        name: this.newWsData.name,
        p_list: this.newWsData.p_list,
        type: this.ws_type,
        // 添加池
      }).then((res) => {
        if (res.code == 10000) {
          this.addWsShow = false
          this.$toast.success('新增车间成功!')
          this.getPoolData()
        }
      })
    },
    beforeClose(action, done) {
      switch (action) {
        case 'cancel':
          done()
          break
        case 'confirm':
          done(false)
          break
      }
    },
    notClose(action, done) {
      done(false)
    },

    columnWidth() {
      return undefined
    },
    toPage(type, item) {
      item.cached = true
      switch (type) {
        case 'poolDetail':
          if (this.ws_type == 2) {
            this.$toPage(this, 'sPoolDetail', item)
          } else if (this.ws_type == 3) {
            this.$toPage(this, 'aPoolDetail', item)
          }
          break
        case 'batch':
          if (this.ws_type == 1) {
            this.$toPage(this, 'larvaProduct', item)
            break
          } else if (this.ws_type == 2) {
            this.$toPage(this, 'sBatchDetail', item)
            break
          } else if (this.ws_type == 3) {
            this.$toPage(this, 'algaListDetail', {
              algae_key: item.algae_key,
            })
          }
          break

        case 'sBatchDetail':
          this.$toPage(this, 'sBatchDetail', item)
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bold-title {
  display: flex;
  color: @titleColor;
  margin-left: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  align-items: center;
}
.row-between {
  padding: 1rem;
}
</style>
