import { isFunction } from '@/utils/utils';
import { isObject } from 'lodash-es';

export const unitFormatter = (unit) => (row, column, cellValue) => {
  if (typeof cellValue === 'undefined') return '暂无';
  const _u = unit ?? row?.unit ?? '';
  return cellValue + _u;
};

export const unitReducer = (unit) => (obj, cur) => ({
  ...obj,
  [cur]: {
    formatter: unitFormatter(unit),
  },
});

// ElTable 配置化
// 通过在对字段, 也即 props, 进行配置.
export default {
  computed: {
    formatterMixin() {
      return (prop) => this.tablePropsConfig?.[prop]?.formatter;
    },
  },
  created() {
    if (!isObject(this.tablePropsConfig)) {
      console.warn('使用 ElTableMixin, 请配置 tablePropsConfig');
    }
  },
  methods: {
    handleCellClickMixin(row, column) {
      const fn = this.tablePropsConfig?.[column.property]?.onClick;
      if (isFunction(fn)) fn(row);
    },
    cellStyleMixin({ column }) {
      return this.tablePropsConfig?.[column.property]?.cellStyle;
    },
  },
};
