<template>
  <div class="main">
    <button
      class="yy-check"
      v-for="(item, index) in list"
      :class="{
        active:
          (currentIndex == index || currentIndexGroup.includes(item.key)) &&
          !item.disabled,
        'is-disabled': item.disabled,
      }"
      :disabled="item.disabled"
      :key="item.key"
      @click="change(index, item.key)"
    >
      {{ item.name || item.key }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'checkButtonGroup',
  props: {
    list: {
      type: Array,
      default() {
        return [
          {
            key: '1', // 必传
            name: '1',
          },
        ]
      },
    },
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    isCheck: {
      type: [String, Array, Number],
      default() {
        return undefined
      },
    },
    value: {
      type: [],
      default: () => [],
    },
  },

  data() {
    return {
      currentIndex: undefined,
      currentIndexGroup: [],
    }
  },
  created() {
    if (this.isCheck && Array.isArray(this.isCheck) && this.isCheckbox) {
      this.currentIndexGroup = this.isCheck
    } else if (!this.isCheckbox) {
      this.list.some((item, index) => {
        if (item.key == this.isCheck) {
          this.currentIndex = index
          return true
        }
      })
    }
  },

  watch: {
    list() {
      if (this.isCheck && Array.isArray(this.isCheck) && this.isCheckbox) {
        this.currentIndexGroup = this.isCheck
      } else if (!this.isCheckbox) {
        this.list.some((item, index) => {
          if (item.key == this.isCheck) {
            this.currentIndex = index
            return true
          }
        })
      }
    },
  },
  methods: {
    change(index, key = undefined) {
      if (this.isCheckbox) {
        //判断是否是多选
        if (!this.currentIndexGroup.includes(key)) {
          //判断是否为第二次点击
          if (key === 'all') {
            this.currentIndexGroup = this.list.map((item) => item.key)
          } else {
            this.currentIndexGroup.push(key)

            if (
              this.currentIndexGroup.filter((item) => item.key != 'all')
                .length ==
                this.list.length - 1 &&
              this.list[0].key == 'all'
            ) {
              this.currentIndexGroup = this.list.map((item) => item.key)
            }
            // else {
            //   this.currentIndexGroup = this.currentIndexGroup.filter(
            //     (item) => item != 'all',
            //   )
            // }
          }
          // 过滤数组中的全部
          this.currentIndexGroup[0]?.key == 'all' &&
            this.currentIndexGroup.shift()
          this.$emit('check', this.currentIndexGroup)
          this.$emit('input', this.currentIndexGroup)
        } else {
          //第二次点击时将此元素移除
          if (key === 'all') {
            this.currentIndexGroup = []
          } else {
            this.currentIndexGroup = this.currentIndexGroup.filter(
              (item) => key && item != key,
            )
            this.currentIndexGroup = this.currentIndexGroup.filter(
              (item) => item != 'all',
            )
          }
          // 过滤数组中的全部
          this.currentIndexGroup[0]?.key == 'all' &&
            this.currentIndexGroup.shift()
          this.$emit('check', this.currentIndexGroup)
          this.$emit('input', this.currentIndexGroup)
        }
      } else {
        //单选时操作
        this.currentIndex = index
        this.$emit('check', key)
        this.$emit('input', [key])
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-wrap: wrap;
}
.yy-check {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  background: rgba(245, 102, 2, 0.55);
  color: rgb(246, 248, 250);

  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0.4rem 0.8rem;
  transition: 0.1s;
  font-weight: 500;
  // 禁止元素的文字被选中
  -webkit-user-modify: read-only;

  user-select: none;
  border: none;
  padding: 12px;
  font-size: 1.3rem;
  border-radius: 4px;
}
.active {
  color: rgb(246, 248, 250);
  border-color: rgb(249, 108, 10);
  background-color: rgb(249, 108, 10);
}
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
