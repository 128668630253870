//  公共筛选项
export function filters() {
  return {
    cultivatesDay: {
      label: '培育天数',
      children: [
        {
          keyName: 'date',
          label: '请选择培育天数',
          children: [
            { label: '1天', key: '1' },
            { label: '2天', key: '2' },
            { label: '3天', key: '3' },
            { label: '4天', key: '4' },
          ],
        },
      ],
    },
    container: {
      label: '容器',
      remote: async () => {
        let containers = this.containerList
        return [
          {
            label: '请选择容器',
            keyName: 'container',
            children: containers.map((container) => ({
              label: container.name,
              key: container.key,
            })),
          },
        ]
      },
    },
    distributeStatus: (keyName = 'finish') => {
      let children = [
        {
          label: '已分配',
          key: 1,
        },
        {
          label: '部分分配',
          key: 2,
        },
        {
          label: '未分配',
          key: 3,
        },
      ]

      if (keyName == 'status') {
        children = [
          {
            label: '已分配',
            key: 3,
          },
          {
            label: '部分分配',
            key: 2,
          },
          {
            label: '未分配',
            key: 1,
          },
        ]
      }
      return {
        label: '分配状态',
        keyName,
        children,
      }
    },
  }
}
