import { filters } from './algaFilterPreset'
import { WordMap } from '@/utils/wordMap'

export const algaListItemWordMapInstance = new WordMap({
  stage: '阶段',
  container: '容器',
  c_number: '容器数量',
  container_number: '容器数量',
  container_num: '容器数量',
  breed_day: { value: '总培养', suffix: '天' },
  breed_date: '培育天数',
  record_date: '操作日期',
  ass_date: '分配日期',
  ass_num: '已分配池',
  loss_num: '淘汰容器',
  ass_number: '分配池数',
  ori_stage: '原阶段',
  ori_container: '原容器',
  ori_date: '原培育',
  ori_cnumber: '原容器数量',
  adj_container: '新容器',
  adj_number: '新容器数',
  adj_stage: '调整阶段',
  loss: '淘汰数量',
  distribute: {
    mc_name: '基地',
    status: '分配状态',
    total_distribute: '总分配量',
    quantity: '需求量',
    reque_day: '需求日期',
    count: '池数',
    pool_count: '池数',
    batch: '分配批次',
    operate_man: '操作人',
    nickname: '申请人',
    created_day: '申请时间',
    operate_time: '操作日期',
    expecting_distribute: '待分配量',
    tag: '时间',
  },
})

export const algaListItemWordMap = algaListItemWordMapInstance.getPureObj()

// 不同等级下筛选项，以及每个卡片的显示数据
function algaPage() {
  return {
    'rank-three': {
      tabLabel: '三级',
      stage: 4,
      filterOptions: [
        filters.apply(this).distributeStatus(),
        filters.apply(this).cultivatesDay,
      ],
      cardFiled: [
        'container',
        'c_number',
        'breed_day',
        'record_date',
        'ass_date',
        'ass_num',
      ],
    },
    'rank-two': {
      tabLabel: '二级',
      stage: 3,
      filterOptions: [
        filters.apply(this).container,
        filters.apply(this).cultivatesDay,
      ],
      cardFiled: ['container', 'c_number', 'record_date', 'breed_day'],
    },
    'rank-first': {
      tabLabel: '一级',
      stage: 2,
      filterOptions: [
        filters.apply(this).container,
        filters.apply(this).cultivatesDay,
      ],
      cardFiled: ['container', 'c_number', 'record_date', 'breed_day'],
    },
    convation: {
      tabLabel: '保种',
      stage: 1,
      filterOptions: [
        filters.apply(this).container,
        {
          label: '放入日期',
          keyName: ['start_date', 'end_date'],
        },
      ],
      cardFiled: ['container', 'c_number', 'record_date', 'breed_day'],
    },
  }
}

const mapHandler = (item) => ({
  label: item?.content?.name,
  key: item.key,
})

export default {
  data() {
    return {
      algaPage: algaPage.apply(this),
      algaListItemWordMap,
      mixinParams: {}, // 参数缓存,
      filterOptionsPreset: [
        {
          label: '品种',
          remote: async () => {
            const {
              data: { list },
            } = await this.$get('/conf/getNewList', { type: 12 })
            return [
              {
                label: '请选择品种',
                keyName: 'breed',
                children: list.map(mapHandler),
              },
            ]
          },
        },
        {
          label: '来源',
          remote: async () => {
            const {
              data: { list },
            } = await this.$get('/conf/getNewList', { type: 13 })
            return [
              {
                label: '请选择来源',
                keyName: 'source',
                children: list.map(mapHandler),
              },
            ]
          },
        },
      ],
    }
  },
  computed: {
    mixinStageMap() {
      return Object.values(this.algaPage).reduce((obj, acc) => {
        return {
          ...obj,
          [acc.stage]: acc.tabLabel,
        }
      }, {})
    },
    tabData() {
      return Object.keys(this.algaPage).map((page) => {
        return {
          name: page,
          label: this.algaPage[page].tabLabel,
        }
      })
    },
    filterOptions({ filterOptionsPreset }) {
      return filterOptionsPreset.concat(
        this.algaPage[this.tabCur].filterOptions,
      )
    },
  },
  methods: {
    filterReset() {
      this.initData()
      this.mixinParams = {}
      this.getData()
    },
    mixinFilterSearch(e) {
      this.initData()
      this.mixinParams = e
      this.getData(1, this.count, e)
    },
    mixinHandleTabChange() {
      this.initData()
      this.getData()
    },
  },
}
