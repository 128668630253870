<template>
  <div class="yiyu-tab-pane" ref="tabPane" :style="bgStyle">
    <div class="tab-wrap" @click="fn">
      <div
        class="tab-top"
        :style="style"
        :class="{ active: activeName === name }"
      >
        {{ label }}<slot></slot>
      </div>
      <div
        class="tab-border"
        v-if="activeName === name && type == 'line'"
        :style="{ background: borderColor, width: width }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'yiyuTabPane',
  props: {
    name: [String, Number],
    label: String,
  },
  data() {
    return {
      parent: this.$parent,
      colors: this.$parent.colors,
      fontSizes: this.$parent.fontSizes,
      borderColor: this.$parent.borderColor,
      width: this.$parent.width,
      type: this.$parent.type,
    }
  },
  computed: {
    activeName() {
      return this.parent.$attrs.value
    },
    style() {
      if (this.activeName === this.name) {
        if (this.type == 'line') {
          return `color: ${this.colors[1]};font-size: ${this.fontSizes[1]}`
        } else if (this.type == 'card') {
          return `color: ${this.colors[1]};font-size: ${this.fontSizes[1]}`
        }
      } else {
        if (this.type == 'line') {
          return `color: ${this.colors[0]};font-size: ${this.fontSizes[0]}`
        } else if (this.type == 'card') {
          return `color: ${this.colors[0]};font-size: ${this.fontSizes[0]}`
        }
      }
      return undefined
    },
    bgStyle() {
      if (this.activeName === this.name) {
        if (this.type == 'line') {
          return ``
        } else if (this.type == 'card') {
          return `background: #fff; border: 0.5px solid #E6E6E6;`
        }
      } else {
        if (this.type == 'line') {
          return ``
        } else if (this.type == 'card') {
          return `text-align: center;background: #E6E6E6; border: 0.5px solid #E6E6E6;`
        }
      }
      return undefined
    },
  },
  methods: {
    fn() {
      this.parent.$emit('input', this.name)
      this.parent.$emit('change', this.name)
      let tabPane = this.$refs['tabPane']
      let tabScroll = this.parent.$refs['tabScroll']
      let scrollWidth =
        (tabScroll && tabScroll.getBoundingClientRect().width) || 0
      // 最大滚动距离
      let max = tabScroll.scrollWidth - scrollWidth
      if (tabPane.offsetLeft > scrollWidth / 2) {
        tabScroll.scrollLeft = max
      } else if (tabPane.offsetLeft < scrollWidth / 2) {
        tabScroll.scrollLeft = 0
      }
    },
  },
}
</script>
<style lang="less" scoped>
.yiyu-tab-pane {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
.tab-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;

  .tab-top {
    flex-basis: 100%;
    width: 100%;
    padding: 0 0.25rem;
    text-align: center;

    &.active {
      font-weight: bold;
    }
  }

  :last-of-type {
    margin-right: 0;
  }

  .tab-border {
    height: 3px;
    margin-top: 4px;
    padding: 0 0.25rem;
  }
}
</style>
