<template>
  <div class="yiyu-handle-tag">
    <div class="tag-wrap" v-for="(item, index) in list" :key="index">
      <van-field v-model="list[index]['name']" :readonly="!editable">
        <template #button>
          <van-icon v-if="deletable" name="cross" @click="del(index)" />
        </template>
      </van-field>
    </div>
    <div
      class="tag-wrap add-tag"
      @click="add"
      v-if="addable && (list.length || addShow)"
    >
      + 新增
    </div>
  </div>
</template>

<script>
// 可以增删改的tag
export default {
  name: 'handleTag',
  props: {
    list: {
      // 在编辑的时候，会直接修改该值
      type: Array,
      default: () => [],
    },
    index: {
      // 其实是个无意义参数，只做存取，不能删
      type: Number,
    },
    addShow: {
      // 是否展示新增按钮
      type: Boolean,
      default: false,
    },
    editable: {
      // 是否可编辑
      type: Boolean,
      default: true,
    },
    addable: {
      // 是否可新增的
      type: Boolean,
      default: true,
    },
    deletable: {
      // 是否可删除
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    add() {
      this.$emit('add', this.index)
    },
    del(index) {
      this.$emit('del', index)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-handle-tag {
  display: flex;
  flex-wrap: wrap;
  .tag-wrap {
    margin: 4px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    /deep/ .van-cell.van-field {
      height: 100%;
      background: rgba(82, 98, 124, 0.08);
      border-radius: 8px;
      align-items: center;
      .van-field__control {
        text-align: left;
        width: auto;
      }
      .van-field__button {
        display: flex;
        align-items: center;
      }
    }

    &.add-tag {
      background: @mainColor;
      border-radius: 8px;
      color: #fff;
      text-align: center;
      padding: 0 0.5rem;
    }
  }
}
</style>
