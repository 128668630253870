<template>
  <!-- <van-sticky class="yiyu-tabs-scroll" ref="tabScroll" offset-top="2.87rem"> -->
  <div class="yiyu-tabs-scroll" ref="tabScroll">
    <div class="yiyu-tabs">
      <slot></slot>
    </div>
  </div>
  <!-- </van-sticky> -->
</template>

<script>
import { MAIN_COLOR } from '@/utils/globar'
export default {
  name: 'yiyuTabs',
  // 可以绑定change事件，参数val即为选中的值
  props: {
    type: {
      type: String,
      default: 'line', // card
    },
    // [默认，选中]
    colors: {
      type: Array,
      default: () => ['#333', '#000'],
    },
    fontSizes: {
      type: Array,
      default: () => ['1rem', '1.1rem'],
    },
    borderColor: {
      type: String,
      default: MAIN_COLOR,
    },
    // 选中下划线宽度相对文本宽度的百分数
    // 支持px
    width: {
      type: String,
      default: '40%',
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-tabs-scroll {
  display: flex;
  overflow-x: auto;
}
.yiyu-tabs {
  background: #fff;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  min-width: 90%;
  flex-shrink: 0;

  box-sizing: content-box;
  div {
    display: flex;
  }
}
</style>
